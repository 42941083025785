import { Card } from '@/components/ui/card';
import { cn } from "@/lib/utils";

interface Props {
    data: {
        combinedData: Array<{
            signature: string;
            percentageData: Record<string, any>;
            topicDescription: {
                topicVideoLink: string | null;
                _id: string;
                prompt: string;
                topicCode: string;
                topicHeading: string;
                topicDescription: string;
                __v: number;
                topicImage: string;
            };
        }>;
    };
}

export function PrakritiTestResult({ data }: Props) {
    const prakritiData = data.combinedData[0];

    return (
        <Card className="max-w-4xl mx-auto p-8 rounded-xl shadow-lg bg-[var(--background)]">
      
            <div className="text-center mb-8">
                <h2 
                    className="text-2xl md:text-3xl font-bold text-white bg-[var(--primary)] px-4 py-2 rounded-md w-full block"
                >
                    {prakritiData.topicDescription.topicHeading}
                </h2>
            </div>

            {/* Content Section */}
            <div className="space-y-6">
                <div 
                    className={cn(
                        "prose prose-lg max-w-none",
                        "prose-headings:text-black prose-headings:font-semibold",
                        "prose-p:text-gray-700 prose-p:leading-relaxed",
                        "prose-strong:text-black prose-strong:font-bold"
                    )}
                    dangerouslySetInnerHTML={{ 
                        __html: prakritiData.topicDescription.topicDescription 
                    }}
                />

                {/* Signature Section */}
                {prakritiData.signature && (
                    <div className="mt-8 pt-4 border-t border-gray-200">
                        <p className="text-lg font-semibold text-black flex items-center gap-2">
                            <span className="text-gray-600">Signature:</span>
                            <span className="font-medium">{prakritiData.signature}</span>
                        </p>
                    </div>
                )}
            </div>
        </Card>
    );
}