import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { IndianRupee, Users, Package, Crown, CalendarDays, Loader2 } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Subscription, SubscriptionPlan } from "@/types/subscription";
import { useSubscriptionsTab } from "./useSubscriptionsTab";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import { useState } from "react";
import { useAuth } from "@/contexts/AuthContext";
import { useTeamDetails } from "../useTeamDetails";

export function SubscriptionsTab() {
    const { teamId } = useParams();
    const { teamSubscriptions, isLoading } = useSubscriptionsTab(teamId);
    const [showDescriptionPlan, setShowDescriptionPlan] = useState<SubscriptionPlan | null>(null);
    const { user } = useAuth();
    const { team } = useTeamDetails();

    const isOwner = user?.id === team?.ownerId;

    if (isLoading) {
        return (
            <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
                    <div className="space-y-1">
                        <div className="h-6 w-32 bg-muted animate-pulse rounded" />
                        <div className="h-4 w-48 bg-muted animate-pulse rounded" />
                    </div>
                </CardHeader>
                <CardContent>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {[1, 2].map((i) => (
                            <Card key={i} className="flex flex-col">
                                <CardHeader>
                                    <div className="h-8 w-48 mb-2 bg-muted animate-pulse rounded" />
                                    <div className="h-4 w-full bg-muted animate-pulse rounded" />
                                </CardHeader>
                                <CardContent>
                                    <div className="h-12 w-32 mb-6 bg-muted animate-pulse rounded" />
                                    <div className="space-y-4">
                                        {[1, 2].map((j) => (
                                            <div key={j} className="h-4 w-full bg-muted animate-pulse rounded" />
                                        ))}
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </CardContent>
            </Card>
        );
    }

    if (!teamSubscriptions.length) {
        return (
            <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
                    <div className="space-y-1">
                        <CardTitle>Subscriptions</CardTitle>
                        <CardDescription>Manage your team's subscription plans</CardDescription>
                    </div>
                    {isOwner ? (
                        <Button 
                            variant="orange" 
                            onClick={() => window.location.href = `/subscription?teamId=${teamId}`}
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Loading
                                </>
                            ) : (
                                'Subscribe Now'
                            )}
                        </Button>
                    ) : (
                        <Button 
                            variant="outline" 
                            disabled
                        >
                            Only owner can subscribe
                        </Button>
                    )}
                </CardHeader>
                <CardContent>
                    <div className="flex flex-col items-center justify-center py-8 text-center">
                        <Package className="h-12 w-12 text-muted-foreground/50" />
                        <p className="mt-4 text-lg font-medium text-muted-foreground">
                            No Active Subscriptions
                        </p>
                        <p className="mt-2 text-sm text-muted-foreground">
                            Subscribe to a plan to unlock premium features for your team.
                        </p>
                    </div>
                </CardContent>
            </Card>
        );
    }

    const renderPlanCard = (subscription: Subscription) => (
        <Card key={subscription._id} className="flex flex-col border-2 border-primary/20">
            <CardHeader>
                <div className="flex items-center justify-between">
                    <div className="space-y-1">
                        <CardTitle className="flex items-center">
                            {subscription.plan.name}
                            <Badge variant="default" className="ml-2">
                                <Crown className="w-3 h-3 mr-1" />
                                Active
                            </Badge>
                        </CardTitle>
                        <div className="space-y-2">
                            <div className="line-clamp-2 prose prose-sm max-w-none dark:prose-invert">
                                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                    {subscription.plan.description}
                                </ReactMarkdown>
                            </div>
                            {subscription.plan.description && subscription.plan.description.length > 100 && (
                                <Button 
                                    variant="link" 
                                    className="p-0 h-auto text-sm"
                                    onClick={() => setShowDescriptionPlan(subscription.plan)}
                                >
                                    More info
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </CardHeader>
            <CardContent className="flex-grow">
                <div className="flex items-baseline mb-6">
                    <IndianRupee className="w-4 h-4 mr-1" />
                    <span className="text-3xl font-bold">
                        {subscription.price}
                    </span>
                    <span className="text-muted-foreground ml-2">/ month</span>
                </div>
                <div className="space-y-3">
                    <div className="flex items-center text-sm">
                        <Users className="w-4 h-4 mr-2 text-muted-foreground" />
                        <span>Seats: {subscription.seats}</span>
                    </div>
                    <div className="flex items-center text-sm">
                        <CalendarDays className="w-4 h-4 mr-2 text-muted-foreground" />
                        <span>Active until {format(new Date(subscription.endDate), "MMM d, yyyy")}</span>
                    </div>
                </div>
            </CardContent>
            <CardFooter className="pt-4">
                <Button
                    variant="ghost"
                    className="w-full"
                    onClick={() => setShowDescriptionPlan(subscription.plan)}
                >
                    Learn More
                </Button>
            </CardFooter>
        </Card>
    );

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
                <div className="space-y-1">
                    <CardTitle>Subscriptions</CardTitle>
                    <CardDescription>Manage your team's subscription plans</CardDescription>
                </div>
                {!teamSubscriptions.length && isOwner && (
                    <Button 
                        variant="orange" 
                        onClick={() => window.location.href = `/subscription?teamId=${teamId}`}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <>
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                Loading
                            </>
                        ) : (
                            'Subscribe Now'
                        )}
                    </Button>
                )}
            </CardHeader>
            <CardContent>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {teamSubscriptions.map((subscription) => {
                        // Get plan details from subscription
                        const plan = subscription.plan as SubscriptionPlan;
                        
                        return renderPlanCard(subscription);
                    })}
                </div>
            </CardContent>

            {/* Plan Description Modal */}
            <Dialog 
                open={!!showDescriptionPlan} 
                onOpenChange={(open) => !open && setShowDescriptionPlan(null)}
            >
                <DialogContent className="sm:max-w-[500px] max-h-[80vh] overflow-hidden flex flex-col">
                    <DialogHeader>
                        <DialogTitle>{showDescriptionPlan?.name}</DialogTitle>
                    </DialogHeader>
                    <div className="space-y-4 overflow-y-auto pr-6 -mr-6">
                        <div className="prose prose-sm max-w-none dark:prose-invert">
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                {showDescriptionPlan?.description || ''}
                            </ReactMarkdown>
                        </div>
                        {showDescriptionPlan?.bulkDiscounts && (
                            <div className="pt-4 border-t">
                                <h4 className="font-medium mb-2">Volume Discounts</h4>
                                <div className="grid grid-cols-3 gap-4">
                                    {Object.entries(showDescriptionPlan.bulkDiscounts)
                                        .sort(([seats1], [seats2]) => parseInt(seats1) - parseInt(seats2))
                                        .map(([seats, discount]) => (
                                            <div key={seats} className="text-center p-3 bg-secondary rounded-lg">
                                                <p className="font-medium">{discount}% off</p>
                                                <p className="text-sm text-muted-foreground">≥ {seats} seats</p>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </Card>
    );
}
