import React from 'react';
import { Card } from '@/components/ui/card';
import { TongueAttribute } from '../../types/tongueAnalysis';

interface TongueAnalysisProps {
  analysisData: {
    message: string;
    data: {
      response: {
        tongue_image_analysis: {
          attributes: {
            [key: string]: TongueAttribute;
          };
          final_analysis: string;
          image_type: string;
        };
      };
    };
  };
}

const TongueAnalysis: React.FC<TongueAnalysisProps> = ({ analysisData }) => {
  const tongueAnalysis = analysisData?.data?.response?.tongue_image_analysis;
  
  if (!tongueAnalysis) {
    return (
      <Card className="p-6 mb-6">
        <p className="text-sm text-muted-foreground">No tongue analysis data available</p>
      </Card>
    );
  }

  const { attributes, final_analysis } = tongueAnalysis;

  return (
    <Card className="p-6 mb-6 bg-[var(--background)]">
      <h2 className="text-2xl font-semibold mb-4">
        Tongue Analysis Results
      </h2>

      <div className="flex flex-col space-y-4">
        {Object.entries(attributes).map(([key, value]) => (
          <Card key={key} className="p-4 bg-secondary/20">
            <h3 className="text-lg font-semibold capitalize mb-2">
              {key.replace(/_/g, ' ')}
            </h3>
            <div className="mb-2">
              <p className="text-sm text-muted-foreground">
                Description:
              </p>
              <p className="text-sm">{value.description}</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">
                Ayurvedic Analysis:
              </p>
              <p className="text-sm">{value.ayurvedic_analysis}</p>
            </div>
          </Card>
        ))}
      </div>

      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-2">
          Final Analysis
        </h3>
        <p className="text-sm">{final_analysis}</p>
      </div>
    </Card>
  );
};

export default TongueAnalysis;