import { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSectionQuestions } from "./useSectionQuestions";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { Progress } from "@/components/ui/progress";
import { ResizablePanel } from "@/components/ui/resizable";
import { cn } from "@/lib/utils";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { ImageWithFallback } from "@/components/ui/image-with-fallback";
import { SectionQuestionsSkeleton } from "./SectionQuestionsSkeleton";
import withLayout from "@/hoc/withLayout";
import PatientLayout from "@/layouts/Patient/PatientLayout";
import { LOADING_LOGO_URL } from "@/constants";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";

const SectionQuestions = () => {
  const { assessmentId } = useParams();
  const questionsContainerRef = useRef<HTMLDivElement>(null);

  const {
    currentSection,
    sections,
    test,
    isLoading,
    getAnsweredQuestionsCount,
    handleAnswerSelect,
    handleNext,
    handlePrevious,
    isSubmitting,
    isNavigating,
    showDescription,
    setShowDescription,
    isSubmittingAssessment,
    confirmCompleteAssessment,
    handleCompleteAssessment,
  } = useSectionQuestions(assessmentId as string);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const scrollQuestionsToTop = () => {
    if (questionsContainerRef.current) {
      questionsContainerRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    scrollQuestionsToTop();
  }, [currentSection?.id]);

  if (isLoading || !currentSection) {
    return <SectionQuestionsSkeleton />;
  }

  const answeredCount = getAnsweredQuestionsCount();
  const totalQuestions = currentSection?.questions.length || 0;
  const progressPercentage = (answeredCount / totalQuestions) * 100;

  const isFirstSection = sections.indexOf(currentSection!) === 0;
  const isLastSection = sections.indexOf(currentSection!) === sections.length - 1;

  return (
    <div className="flex flex-col h-[calc(100vh-64px)]">
      {/* Sticky Header */}
      <div className="sticky top-0 bg-white z-10 border-b">
        <div className="container mx-auto px-4 py-3">
          {/* Test and Section Info */}
          <div className="flex items-center justify-between mb-2">
            <div>
              <h1 className="text-lg font-bold text-gray-900">{test?.name}</h1>
              <div className="flex items-center text-sm text-muted-foreground">
                <span>Section {sections.indexOf(currentSection!) + 1} of {sections.length}:</span>
                <span className="ml-1 font-medium">{currentSection?.name}</span>
              </div>
            </div>
            <div className="text-right">
              <span className="text-sm font-medium">{Math.round(progressPercentage)}%</span>
              <div className="text-sm text-muted-foreground">
                {answeredCount}/{totalQuestions} answered
              </div>
            </div>
          </div>
          <Progress value={progressPercentage} className="h-1.5" />
        </div>
      </div>

      <div className="flex-1 overflow-hidden">
        <div className="container mx-auto px-4 py-4">
          {/* Mobile Toggle */}
          <div className="md:hidden flex space-x-2 mb-4">
            <Button
              variant={showDescription ? "orange" : "outline"}
              onClick={() => setShowDescription(true)}
              className="flex-1"
            >
              {currentSection?.name}
            </Button>
            <Button
              variant={!showDescription ? "orange" : "outline"}
              onClick={() => setShowDescription(false)}
              className="flex-1"
            >
              Questions
            </Button>
          </div>

          {/* Mobile View */}
          <div className="md:hidden h-[calc(100vh-240px)] overflow-hidden">
            {showDescription ? (
              <Card className="p-6 mb-4 h-full overflow-y-auto">
                <div 
                  className="prose prose-sm max-w-none prose-headings:font-semibold prose-headings:mb-2 prose-p:mt-1 prose-p:mb-2 prose-ul:mt-1 prose-ul:mb-2 prose-li:mt-0.5 prose-li:mb-0.5"
                  dangerouslySetInnerHTML={{ __html: currentSection?.description || '' }}
                />
              </Card>
            ) : (
              <div 
                ref={questionsContainerRef}
                className="h-full overflow-y-auto space-y-4 pb-48 px-0.5"
              >
                {currentSection?.questions.map((question) => (
                  <Card key={question.id} className="flex flex-col">
                    <CardHeader>
                      <CardTitle>{question.text}</CardTitle>
                    </CardHeader>
                    <CardContent className="flex-grow">
                      <RadioGroup
                        value={question.selectedAnswer || ""}
                        className="space-y-3"
                      >
                        {question.answers.map((answer) => (
                          <div
                            key={answer.id}
                            onClick={() => !isSubmitting && !isNavigating && handleAnswerSelect(question.id, answer.text)}
                            className={cn(
                              "flex items-center space-x-3 p-4 rounded-lg border transition-colors",
                              question.selectedAnswer === answer.text
                                ? "bg-[var(--background)] border-[var(--primary)]"
                                : "hover:bg-muted/50 border-transparent",
                              (isSubmitting || isNavigating) && "opacity-50 cursor-not-allowed"
                            )}
                          >
                            <RadioGroupItem 
                              value={answer.text} 
                              id={`${question.id}-${answer.id}`}
                              className={question.selectedAnswer === answer.text ? "text-[var(--primary)]" : "text-black"}
                              disabled={isSubmitting || isNavigating}
                            />
                            {answer.image && (
                              <ImageWithFallback
                                src={answer.image}
                                alt={answer.text}
                                className="w-16 h-16 object-cover rounded-md"
                              />
                            )}
                            <Label
                              htmlFor={`${question.id}-${answer.id}`}
                              className="flex-grow cursor-pointer"
                            >
                              {answer.text}
                            </Label>
                          </div>
                        ))}
                      </RadioGroup>
                    </CardContent>
                  </Card>
                ))}
              </div>
            )}
          </div>

          {/* Desktop View */}
          <div className="hidden md:flex h-full gap-8">
            {/* Left side - Section info (Sticky) */}
            <ResizablePanel defaultSize={40} minSize={30} maxSize={60}>
              <div className="h-full">
                <Card className="p-6">
                  <div 
                    className="prose prose-sm max-w-none prose-headings:font-semibold prose-headings:mb-2 prose-p:mt-1 prose-p:mb-2 prose-ul:mt-1 prose-ul:mb-2 prose-li:mt-0.5 prose-li:mb-0.5"
                    dangerouslySetInnerHTML={{ __html: currentSection?.description || '' }}
                  />
                </Card>
              </div>
            </ResizablePanel>

            {/* Right side - Questions (Scrollable) */}
            <div 
              ref={questionsContainerRef}
              className="flex-1 overflow-y-auto max-h-[calc(100vh-140px)]"
            >
              <div className="space-y-4 pb-48">
                {currentSection?.questions.map((question) => (
                  <Card key={question.id} className="flex flex-col">
                    <CardHeader>
                      <CardTitle>{question.text}</CardTitle>
                    </CardHeader>
                    <CardContent className="flex-grow">
                      <RadioGroup
                        value={question.selectedAnswer || ""}
                        className="space-y-3"
                      >
                        {question.answers.map((answer) => (
                          <div
                            key={answer.id}
                            onClick={() => !isSubmitting && !isNavigating && handleAnswerSelect(question.id, answer.text)}
                            className={cn(
                              "flex items-center space-x-3 p-4 rounded-lg border transition-colors",
                              question.selectedAnswer === answer.text
                                ? "bg-[var(--background)] border-[var(--primary)]"
                                : "hover:bg-muted/50 border-transparent",
                              (isSubmitting || isNavigating) && "opacity-50 cursor-not-allowed"
                            )}
                          >
                            <RadioGroupItem 
                              value={answer.text} 
                              id={`${question.id}-${answer.id}`}
                              className={question.selectedAnswer === answer.text ? "text-[var(--primary)]" : "text-black"}
                              disabled={isSubmitting || isNavigating}
                            />
                            {answer.image && (
                              <ImageWithFallback
                                src={answer.image}
                                alt={answer.text}
                                className="w-16 h-16 object-cover rounded-md"
                              />
                            )}
                            <Label
                              htmlFor={`${question.id}-${answer.id}`}
                              className="flex-grow cursor-pointer"
                            >
                              {answer.text}
                            </Label>
                          </div>
                        ))}
                      </RadioGroup>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Dialog */}
      <Dialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Complete Assessment</DialogTitle>
            <DialogDescription>
              Are you sure you want to complete this assessment? You won't be able to change your answers after completion.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setShowConfirmDialog(false)}
              disabled={isSubmittingAssessment}
            >
              Cancel
            </Button>
            <Button
              variant="orange"
              onClick={confirmCompleteAssessment}
              disabled={isSubmittingAssessment}
            >
              {isSubmittingAssessment ? (
                <>
                  <img 
                    src={LOADING_LOGO_URL} 
                    alt="Loading" 
                    className="w-5 h-5 mr-2"
                  />
                  Completing...
                </>
              ) : (
                "Complete"
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Fixed navigation buttons at the bottom */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg z-50">
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <Button
              variant="outline"
              onClick={handlePrevious}
              disabled={isFirstSection || isSubmitting || isNavigating}
              className="w-[100px] relative"
            >
              Previous
            </Button>
            {isLastSection ? (
              <Button
                variant="orange"
                onClick={() => setShowConfirmDialog(true)}
                disabled={isSubmitting || isNavigating}
                className="w-[120px] relative"
              >
                Complete
              </Button>
            ) : (
              <Button
                variant="outline"
                onClick={handleNext}
                disabled={isLastSection || isSubmitting || isNavigating}
                className="w-[100px] relative"
              >
                {(isSubmitting || isNavigating) ? (
                  <img 
                    src={LOADING_LOGO_URL} 
                    alt="Loading" 
                    className="w-5 h-5 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                  />
                ) : (
                  "Next"
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLayout(PatientLayout)(SectionQuestions);
