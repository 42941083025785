import { useMutation, useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';
import { 
    Subscription, 
    SubscriptionPlan, 
    SubscriptionOrder, 
    CreditBalance,
    PaymentOrder,
    Device,
    SubscriptionAccess
} from '@/types/subscription';

interface VerifyPaymentResponse {
    success: boolean;
    subscription: Subscription;
}

// Get all subscription plans with proper caching for payments
export function useSubscriptionPlans() {
    const api = useApi();
    return useQuery({
        queryKey: ['subscription-plans'],
        queryFn: async () => {
            const { data } = await api.get<SubscriptionPlan[]>('/subscription-plans');
            return data;
        },
        refetchOnMount: 'always',  // Always refetch on mount
        refetchOnWindowFocus: 'always', // Always refetch on window focus
        staleTime: 0,             // Data is always considered stale
        gcTime: 1000 * 60 * 5    // Keep cached data for 5 minutes
    });
}

// Get user's active subscriptions
export function useUserSubscriptions() {
    const api = useApi();
    return useQuery({
        queryKey: ['subscriptions'],
        queryFn: async () => {
            const { data } = await api.get<Subscription[]>('/subscriptions');
            return data;
        }
    });
}

// Get specific subscription details
export function useSubscriptionDetails(subscriptionId: string) {
    const api = useApi();
    return useQuery({
        queryKey: ['subscription', subscriptionId],
        queryFn: async () => {
            const { data } = await api.get<Subscription>(`/subscriptions/${subscriptionId}`);
            return data;
        },
        enabled: !!subscriptionId
    });
}

// Create subscription order
export function useCreateSubscriptionOrder() {
    const api = useApi();
    return useMutation<
        SubscriptionOrder,
        Error,
        {
            planId: string;
            type: "INDIVIDUAL" | "TEAM";
            teamId?: string;
            seats?: number;
        }
    >({
        mutationFn: async (params) => {
            const { data } = await api.post<SubscriptionOrder>('/subscriptions/purchase/create-order', params);
            return data;
        }
    });
}

// Verify subscription payment
export function useVerifySubscriptionPayment() {
    const api = useApi();
    return useMutation<
        VerifyPaymentResponse,
        Error,
        {
            subscriptionId: string;
            paymentData: {
                razorpay_payment_id: string;
                razorpay_order_id: string;
                razorpay_signature: string;
            };
        }
    >({
        mutationFn: async ({ subscriptionId, paymentData }) => {
            const { data } = await api.post<VerifyPaymentResponse>(`/subscriptions/purchase/verify`, {
                orderId: paymentData.razorpay_order_id,
                paymentId: paymentData.razorpay_payment_id,
                signature: paymentData.razorpay_signature,
                subscriptionId
            });
            return data;
        }
    });
}

// Add device to subscription
export function useAddDevice() {
    const api = useApi();
    return useMutation<
        { _id: string; devices: Device[] },
        Error,
        {
            subscriptionId: string;
            deviceData: {
                deviceId: string;
                name: string;
            };
        }
    >({
        mutationFn: async ({ subscriptionId, deviceData }) => {
            const { data } = await api.post(`/subscriptions/${subscriptionId}/devices`, deviceData);
            return data;
        }
    });
}

// Remove device from subscription
export function useRemoveDevice() {
    const api = useApi();
    return useMutation<
        { _id: string; devices: Device[] },
        Error,
        {
            subscriptionId: string;
            deviceId: string;
        }
    >({
        mutationFn: async ({ subscriptionId, deviceId }) => {
            const { data } = await api.delete(`/subscriptions/${subscriptionId}/devices/${deviceId}`);
            return data;
        }
    });
}

// Get credit balance
export function useCreditBalance(subscriptionId: string) {
    const api = useApi();
    return useQuery({
        queryKey: ['subscription-credits', subscriptionId],
        queryFn: async () => {
            const { data } = await api.get<CreditBalance>(`/subscriptions/${subscriptionId}/credits`);
            return data;
        },
        enabled: !!subscriptionId
    });
}

// Get team subscriptions
export function useTeamSubscriptions(teamId: string | undefined) {
    const api = useApi();
    return useQuery({
        queryKey: ['subscriptions', teamId],
        queryFn: async () => {
            const { data } = await api.get<Subscription[]>(`/subscriptions?teamId=${teamId}`);
            return data;
        },
        enabled: !!teamId
    });
}

// Check renewal eligibility
export function useCheckRenewalEligibility() {
    const api = useApi();
    return useMutation<
        {
            eligible: boolean;
            subscription?: {
                _id: string;
                endDate: string;
                status: string;
            };
            message?: string;
        },
        Error,
        { subscriptionId: string }
    >({
        mutationFn: async ({ subscriptionId }) => {
            const { data } = await api.post(`/subscriptions/${subscriptionId}/renew`);
            return data;
        }
    });
}

// Get subscription access
export function useSubscriptionAccess() {
    const api = useApi();
    return useQuery({
        queryKey: ['subscription-access'],
        queryFn: async () => {
            const { data } = await api.get<SubscriptionAccess[]>('/subscription-access');
            return data;
        }
    });
}
