import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import React from "react";

const TermsOfUse: React.FC = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <div className="max-w-4xl mx-auto">
                <Link to="/" className="inline-flex items-center text-sm text-muted-foreground hover:text-primary mb-4">
                    <ArrowLeft className="w-4 h-4 mr-2" />
                    Back to Home
                </Link>
                <Card className="w-full h-full">
                    <CardHeader>
                        <CardTitle className="text-3xl font-bold mb-6">Terms of Use</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <ScrollArea className="h-full w-full pr-4">
                            <div className="space-y-6">
                                <div className="text-sm text-muted-foreground font-montserrat">
                                    <p className="font-semibold mb-8">Last updated: June 01, 2024</p>

                                    <p className="mb-8">
                                        Hi! Welcome to Vedita.ai, an Health Technology Company provides online consultations with qualified Ayurvedic doctors. Our services include scheduling appointments, conducting consultations via video or phone calls, and providing personalized health and lifestyle advice based on Ayurvedic principles operated by and proprietary to Desi LLM PVT. LTD., a Limited Liability Company (doing business as : Vedita.ai Platform or Company) having registration credentials as U86901KA2024PTC186441 in Belagavi, Karnataka, India.
                                    </p>

                                    <p className="mb-8">
                                        Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the
                                    </p>

                                    <p className="mb-8">
                                        _____Vedita.ai (the "Website") operated by Desi LLM PVT. LTD. (hereinafter referred to as "Vedita.ai")("us", "we", or "our").
                                    </p>

                                    <p className="mb-8">
                                        By registering with Vedita.ai, you acknowledge and agree to be bound by the terms and conditions outlined in this User Agreement between you and Vedita.ai. Your acceptance is based on a careful reading and understanding of this Agreement. We retain the right to modify or change these Terms at our discretion. Your continued access or use of the Vedita.ai Platform after any such modifications constitutes your acceptance of the revised Terms. It is important to note that specific terms and conditions may apply to users in particular jurisdictions, as outlined herein. The use of pronouns such as "we," "us," or "our" in this User Agreement refers to Desi LLM PVT. LTD. and its affiliates.
                                    </p>

                                    <div className="text-center space-y-4 my-8">
                                        <p className="font-semibold">
                                            PLEASE READ THESE TERMS OF USE CAREFULLY.
                                        </p>
                                        <p className="font-semibold">
                                            BY CLICKING THE "CREATE ACCOUNT" BUTTON OR BY ACCESSING OR USING THE SERVICES, YOU AGREE TO BE LEGALLY BOUND BY THESE TERMS OF SERVICE AND ALL TERMS INCORPORATED BY REFERENCE.
                                        </p>
                                        <p className="font-semibold">
                                            Vedita.ai EXPRESSLY DISCLAIMS ANY AND ALL RESPONSIBILITY FOR ANY DIRECT OR CONSEQUENTIAL LOSS OR DAMAGE OF ANY KIND WHATSOEVER ARISING DIRECTLY OR INDIRECTLY FROM: I) RELYING ON ANY INFORMATION CONTAINED IN THIS DOCUMENT; II) ANY ERROR, OMISSION OR INACCURACY IN ANY SUCH INFORMATION; III) ANY ACTION RESULTING THEREFROM; IV) USAGE OR ACQUISITION OF PRODUCTS AVAILABLE THROUGH THE WEBSITE.
                                        </p>
                                    </div>

                                    <div className="space-y-6">
                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">1. Acceptance of Terms of Use and Amendments</h3>
                                            <p>
                                                By accessing or using this website, you agree to be bound by the Terms of Use, which may be amended from time to time with or without notice. If you utilize specific services on this website, you are also subject to any applicable rules or guidelines, which are hereby incorporated by reference into these Terms of Use. Please refer to our Privacy Policy, as it is part of and incorporated into these Terms of Use by reference.
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">2. Our Service</h3>
                                            <p>
                                                The services provided to you through our website are offered on an "AS IS" basis. You consent to the exclusive right of the website owners to modify, discontinue, or delete the website and its services at any time without notice and without any liability to you. The owners bear no responsibility or liability for the timeliness, deletion, failure to store, inaccuracy, or improper delivery of any data or information you provide, whether temporarily or permanently.
                                            </p>
                                        </section>
                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">3. Create an Account</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                To create an account on our website, it is imperative to furnish essential details, including your name, gender, contact information, and citizenship. Ensuring the accuracy of the provided information is crucial for the proper functioning of the account and to comply with regulatory requirements. We emphasize the importance of providing correct details to facilitate a seamless account creation process and to maintain the integrity of our services.
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">3.1 Account Deletion</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                You have the right to request deletion of your account and associated data. For detailed information about our account deletion process and timeline, please visit our <a href="/delete-my-account" className="text-primary hover:underline">Account Deletion Policy</a> page. Upon requesting account deletion, your data will be permanently removed from our systems within 90 days, subject to legal and regulatory requirements.
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">4. Access to The Service</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                Vedita.ai is offering a range of services to its clients and customers through this platform which is designed to facilitate global expansion. Participation in the platform's services may incur a fee as specified in the view plan tab on the platform, designed to ensure ongoing development and support. This Platform grants you a limited, revocable, nonexclusive right to access the Service for your own personal use.
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">5. Payment Terms</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                By selecting a payment plan and completing the payment process through Axis Bank Payment or such alternative methods which may change from time to tome at the discretion of Vedita.ai, clients will be granted access to the platform's benefits as specified in the chosen plan. The payment plan options, along with their corresponding features, are outlined on the website view plan tab on the platform. Clients acknowledge that access to the Vedita.ai platform and its associated benefits is contingent upon the successful payment in accordance with the selected plan.
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">6. Privacy Notice</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                The information that we may collect, use and share is subject to our Privacy Policy, as posted on ___Vedita.ai___ (Website).
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">7. Your Conduct</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                You acknowledge that any information or data, including text, photographs, graphics, videos, or other materials ("Content"), whether shared publicly or privately, is the sole responsibility of the individual providing the Content. We disclaim any responsibility to you for the Content displayed on this website, and we shall not be held liable for any errors or omissions related to the Content.
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">8. Third Party Services</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                Third-party goods and services may be promoted or accessible on this website. Statements made concerning products and services offered by third parties are subject to the policies and representations established by those third parties. We do not assume any liability or responsibility for your transactions or interactions with third parties in any way.
                                            </p>
                                        </section>
                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">9. Indemnification</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                You consent to indemnify and protect us, along with our subsidiaries, affiliates, related parties, officers, directors, employees, agents, independent contractors, advertisers, partners, and co-branders, from any claim or demand, including reasonable attorney's fees, asserted by a third party. This claim or demand may arise due to or be associated with your activities or association with this website or Service, your provision of Content, or your breach of these Terms of Use or any other infringement of the rights of another individual or entity.
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">10. Risk Disclosure</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                By accessing and using the services provided by Vedita.ai through its website, you acknowledge and agree to the following risk disclosure: Participation in the activities, events, or any services offered by Vedita.ai Platform may involve inherent risks. These risks include, but are not limited to, physical injury, property damage, or other unforeseen consequences. Members voluntarily assume these risks and agree to exercise caution and prudence while engaging in any Vedita.ai platform-related activities.
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">11. Limitation of Liability</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                You explicitly comprehend and agree that we are not liable for any direct, indirect, special, incidental, consequential, or exemplary damages, which may include but are not limited to damages for loss of profits, goodwill, use, data, or other intangible losses. This applies even if we have been advised of the possibility of such damages.
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">12. Disclaimer of Warranties</h3>
                                            <div className="space-y-4">
                                                <p className="text-sm text-muted-foreground font-montserrat leading-7">
                                                    Unless expressly stated otherwise in written form by us, our services are provided on An "As is" and "As available" basis. We explicitly disclaim, and you waive, all warranties of any kind, whether express or implied.
                                                </p>

                                                <p className="text-sm text-muted-foreground font-montserrat leading-7">
                                                    Including, without limitation, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement concerning our services, including the information, content, and materials contained therein.
                                                </p>

                                                <p className="text-sm text-muted-foreground font-montserrat leading-7">
                                                    You recognize that information you store or transfer through our services may be Irretrievably lost, corrupted, or temporarily unavailable due to various causes. These Causes include but are not limited to software failures, protocol changes by third-party providers, internet outages, force majeure events, or other disasters, including third-party ddos attacks, scheduled or unscheduled maintenance, or other causes either within or outside our control. You are solely responsible for backing up And maintaining duplicate copies of any information you store or transfer through our services.
                                                </p>

                                                <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                    Some jurisdictions do not allow the disclaimer of implied terms in contracts with consumers, so some or all of the disclaimers in this section may not apply to you.
                                                </p>
                                            </div>
                                        </section>

                                       
                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">13. Intellectual Property Rights</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                Vedita.ai owns the Website, including all its contents, features, and functionality (such as information, software, text, displays, images, video, audio, and the design, selection, and arrangement). The ownership extends to Vedita.ai, its affiliated entities, if applicable, or other providers of such material. These assets are safeguarded by the copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws of the India and international jurisdictions.
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">14. Reservation of Rights</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                All rights, including copyrights, trademarks, patents, trade secrets, and any other proprietary rights held by us in our website, its content, and the goods and services offered, are reserved. Prior written consent from us is necessary for the utilization of our rights and property. The availability of our services does not grant you any implied or express licenses or rights, and you are not authorized to make any commercial use of our website or services without obtaining our prior written consent.
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">15. Changes to the Website</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                We reserve the right to periodically update the content on this website; however, the material may not always be comprehensive or current. Any information on the website might become outdated, and we are not obligated to refresh such material.
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">16. Governing Law</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                These Terms will be governed and interpreted in accordance with the laws of Belagavi, Karnataka, India, without consideration of its conflict of law provisions. Our failure to enforce any right or provision of these Terms shall not be deemed a waiver of those rights. Should any provision of these Terms be deemed invalid or unenforceable by a court, the remaining provisions will remain in effect. These Terms constitute the entire agreement between us concerning our Service, supplanting and displacing any prior agreements we may have had regarding the Service.
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">17. Arbitration</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                The Parties agree that any and all controversies or claims arising out of or relating to this Agreement, or any alleged breach hereof, shall be resolved by arbitration as prescribed herein. A single arbitrator engaged in the practice of law, who is knowledgeable about the telecommunications industry and telecommunications law, shall conduct the arbitration under the current rules of the American Arbitration Association ("AAA"), unless otherwise provided herein. The arbitrator shall be selected in accordance with AAA procedures from a list of qualified people maintained by AAA. The arbitration shall be conducted in Belagavi, Karnataka, and all expedited procedures prescribed under the AAA rules shall apply. There shall be no discovery other than the exchange of information, which is provided to the arbitrators by the Parties. The arbitrator shall only have authority to award compensatory damages and shall not have authority to award punitive damages, other non-compensatory damages or any other form of relief. Each Party shall bear its own costs and attorneys' fees and disbursements subject to the award of attorneys' fees by the arbitrator. The arbitrator's decision and award shall be fnal and binding, and judgment upon the award rendered by the arbitrator may be entered in any court having jurisdiction thereof. If any Party fles a judicial or administrative action (other than a Party seeking injunction or other equitable relief as provided in subsection 5(c) above) asserting claims subject to arbitration, as prescribed herein, and another Party successfully stays such action and/or compels arbitration of said claims, the Party fling said action shall pay the other Party's costs and expenses incurred in seeking such stay and/or compelling arbitration, including reasonable attorneys' fees and disbursement.
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">18. Notification of Copyright Infringement</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                If you believe that your property has been used in any way that would be considered copyright infringement or a violation of your intellectual property rights, our concerned person may be contacted at the following address: support@vedita.ai.
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">19. Miscellaneous Information</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                In the event that this Terms of Use conflicts with any law under which any provision may be held invalid by a court with jurisdiction over the parties, such provision will be interpreted to reflect the original intentions of the parties in accordance with applicable law, and the remainder of this Terms of Use will remain valid and intact.
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">20. Entire Agreement</h3>
                                            <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and the Vedita.ai platform regarding the Website and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Website.
                                            </p>
                                        </section>

                                        <section>
                                            <h3 className="text-lg font-semibold mb-4">21. Your Comments and Concerns</h3>
                                            <div className="space-y-4">
                                                <p className="text-sm text-muted-foreground font-montserrat leading-7">
                                                    This website is operated by: Desi LLM PVT. LTD., a Limited Liability Company incorporated in Belagavi, Karnataka.
                                                </p>
                                                <p className="text-sm text-muted-foreground font-montserrat leading-7 mb-8">
                                                    All notices of copyright infringement claims, other feedback, comments, requests for technical support, and other communications relating to the Website should be directed to: support@vedita.ai (email address)
                                                </p>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </ScrollArea>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default TermsOfUse;