import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useMediaQuery } from "@/hooks/use-media-query";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FileText } from "lucide-react";
import { tooltipStyles } from "@/constants";


export function WhatsAppIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
    >
      <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z" />
    </svg>
  );
}
interface OrderedTest {
  _id: string;
  patientName: string;
  patientEmail: string;
  patientMobile: string;
  totalAmount: number;
  currency: string;
  orderedAt: string;
  testCompleted: number;
  totalTests: number;
  testIds: Array<{
    _id: string;
    name: string;
    description: string;
    assessment?: {
      status: string;
      _id: string;
    };
    pricing: {
      price: number;
      currency: string;
      discountPrice?: number;
    };
    preparation?: {
      estimatedTime: {
        hours: number;
        minutes: number;
      };
      instructions: string;
      requirements: string[];
    };
    reportType: "MANUS";

  }>;
  patientGender: string;
  countryCode?: string;
}

interface TestsTableProps {
  tests: OrderedTest[];
  formatDate: (date: Date | string) => string;
  currentPage: number;
  pageSize: number;
}



export function TestsTable({ tests, formatDate, currentPage, pageSize }: TestsTableProps) {
  const isMobile = useMediaQuery("(max-width: 640px)");
  const [selectedOrder, setSelectedOrder] = useState<OrderedTest | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleViewDetails = (order: OrderedTest) => {
    setSelectedOrder(order);
    setIsDialogOpen(true);
  };

  const generateWhatsAppMessage = (order: OrderedTest) => {
    const testNames = order.testIds.map(test => test.name).join(', ');
    const message = `Hello ${order.patientName},\n\nYour test order details:\nTests: ${testNames}\nOrder Date: ${formatDate(order.orderedAt)}\nStatus: ${order.testCompleted}/${order.totalTests} Completed\n\nTo view your test results, please sign up here:\nhttps://www.vedita.ai/register?role=patient`;
    return encodeURIComponent(message);
  };

  const handleWhatsAppShare = (order: OrderedTest) => {
    const message = generateWhatsAppMessage(order);
    const phoneNumber = order.countryCode ? `${order.countryCode}${order.patientMobile}` : order.patientMobile;
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
  };

  const renderTestStatus = (completed: number, total: number) => (
    <span className={`px-2 py-1 rounded-full text-xs ${completed === total
        ? 'bg-green-100 text-green-800'
        : 'bg-orange-100 text-orange-800'
      }`}>
      {`${completed}/${total} Completed`}
    </span>
  );

  const formatTestNames = (tests: Array<{ name: string }>) => {
    if (tests.length <= 2) {
      return tests.map(t => t.name).join(', ');
    }
    return `${tests.slice(0, 2).map(t => t.name).join(', ')}`;
  };

  const getExtraTestsCount = (tests: Array<{ name: string }>) => {
    return tests.length > 2 ? tests.length - 2 : 0;
  };

  const getSerialNumber = (index: number) => {
    return (currentPage - 1) * pageSize + index + 1;
  };

  if (isMobile) {
    return (
      <>
        <div className="space-y-4">
          {tests.map((order) => (
            <Card key={order._id} className="p-4">
              <div className="flex flex-col space-y-3">
                <div className="flex justify-between items-center">
                  {renderTestStatus(order.testCompleted, order.totalTests)}
                  <div className="text-sm font-medium">
                    {order.totalAmount} {order.currency}
                  </div>
                </div>

                <div>
                  <h3 className="font-medium">{order.patientName}</h3>
                  <p className="text-sm text-muted-foreground">{order.patientEmail}</p>
                  <p className="text-sm text-muted-foreground">{order.patientMobile}</p>
                </div>

                <div className="flex justify-between items-center text-sm border-t pt-2">
                  <div className="text-muted-foreground">
                    Order Date: {formatDate(order.orderedAt)}
                  </div>
                </div>

                <div className="flex gap-2">
                  <Button
                    size="sm"
                    onClick={() => handleWhatsAppShare(order)}
                    className="flex-1 bg-[#25D366] hover:bg-[#20b355] text-white"
                  >
                    <WhatsAppIcon className="h-4 w-4 mr-2" />
                    Share on WhatsApp
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => handleViewDetails(order)}
                    variant="orange"
                    className="flex-1"
                  >
                    View Details
                  </Button>
                </div>
              </div>
            </Card>
          ))}
          {tests.length === 0 && (
            <p className="text-center py-4 text-muted-foreground">No orders found.</p>
          )}
        </div>

        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent className="h-screen sm:h-auto sm:max-h-[85vh] overflow-y-auto mx-0 p-0 sm:p-6 sm:mx-4 sm:rounded-lg">
            <DialogHeader className="sticky top-0 bg-white p-4 sm:p-0 border-b space-y-2">
              <DialogTitle className="text-xl font-semibold">Test Details</DialogTitle>
              <div className="flex flex-wrap gap-2">
                {renderTestStatus(selectedOrder?.testCompleted || 0, selectedOrder?.totalTests || 0)}
              </div>
            </DialogHeader>

            {selectedOrder && (
              <div className="p-4 sm:p-0 sm:mt-6 space-y-4">
                {/* Test Details */}
                <div>
                  <h3 className="text-lg font-semibold mb-3">Test Details</h3>
                  <div className="space-y-4">
                    {selectedOrder.testIds.map((test) => (
                      <div
                        key={test._id}
                        className="p-4 border rounded-lg"
                      >
                        <div className="flex flex-col gap-2">
                          <div className="flex justify-between items-start">
                            <h4 className="font-medium">{test.name}</h4>
                            {test.assessment?.status==="COMPLETED" && (
                            <Link
                               to={`/test-results/${test.assessment?._id}/${test.reportType}/${selectedOrder.patientGender}`}
                               className="flex items-center gap-2"
                             >
                               <FileText className="h-4 w-4" />
                               View Report
                             </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Order Summary */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <h3 className="text-lg font-semibold mb-3">Order Summary</h3>
                  <div className="space-y-2">
                    <div className="flex justify-between items-center">
                      <span className="text-gray-600">Status</span>
                      {renderTestStatus(selectedOrder.testCompleted, selectedOrder.totalTests)}
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-gray-600">Order Date</span>
                      <span>{formatDate(selectedOrder.orderedAt)}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </>

    );
  }

  return (
    <>
      {/* Desktop View */}
      <div className="hidden lg:block">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-16">S No.</TableHead>
              <TableHead>Patient Name</TableHead>
              <TableHead>Phone Number</TableHead>
              <TableHead>Tests</TableHead>
              <TableHead>Order Date</TableHead>
              <TableHead>Test Status</TableHead>
              <TableHead className="text-center">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {tests.map((order, index) => (
              <TableRow key={order._id}>
                <TableCell className="font-medium">{getSerialNumber(index)}</TableCell>
                <TableCell>
                  <div>
                    <div className="font-medium">{order.patientName}</div>
                    <div className="text-sm text-muted-foreground">{order.patientEmail}</div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="text-sm">{order.patientMobile}</div>
                </TableCell>
                <TableCell>
                  <TooltipProvider>
                    <Tooltip delayDuration={300}>
                      <TooltipTrigger className="flex items-center gap-1">
                        <span className="cursor-default text-left">
                          {order.testIds[0].name}
                          {order.testIds.length > 1 &&
                            ` +${order.testIds.length - 1} more`}
                        </span>
                      </TooltipTrigger>
                      <TooltipContent className={tooltipStyles} side="top">
                        <p>{order.testIds.map(t => t.name).join(', ')}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </TableCell>
                <TableCell>{formatDate(order.orderedAt)}</TableCell>
                <TableCell>{renderTestStatus(order.testCompleted, order.totalTests)}</TableCell>
                <TableCell className="text-center">
                  <div className="flex justify-center gap-2">
                    <Button
                      size="sm"
                      onClick={() => handleWhatsAppShare(order)}
                      className="bg-[#25D366] hover:bg-[#20b355] text-white"
                    >
                      <WhatsAppIcon className="h-4 w-4" />
                    </Button>
                    <Button
                      size="sm"
                      onClick={() => handleViewDetails(order)}
                      variant="orange"
                    >
                      View Details
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {tests.length === 0 && (
              <TableRow>
                <TableCell colSpan={7} className="text-center py-4">
                  <p className="text-muted-foreground">No orders found.</p>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {/* Tablet and Mobile View */}
      <div className="lg:hidden space-y-4">
        {tests.map((order, index) => (
          <Card key={order._id} className="p-4 hover:shadow-md transition-shadow">
            <div className="flex flex-col space-y-4">
              {/* Header with Name and Date */}
              <div className="flex items-center justify-between border-b pb-3">
                <div className="flex items-center gap-2">
                  <span className="text-sm text-muted-foreground">#{getSerialNumber(index)}</span>
                  <h3 className="font-medium text-base">{order.patientName}</h3>
                </div>
                <div className="text-sm text-muted-foreground">
                  {formatDate(order.orderedAt)}
                </div>
              </div>

              {/* Content */}
              <div className="space-y-3">
                <div className="flex justify-between items-start">
                  <div className="space-y-1">
                    <div className="text-sm text-muted-foreground">{order.patientEmail}</div>
                    <div className="text-sm">{order.patientMobile}</div>
                  </div>
                  <div>{renderTestStatus(order.testCompleted, order.totalTests)}</div>
                </div>

                <div className="flex justify-between items-center text-sm">
                  <span className="text-muted-foreground">Tests:</span>
                  <TooltipProvider>
                    <Tooltip delayDuration={300}>
                      <TooltipTrigger className="flex items-center gap-1">
                        <span className="cursor-default text-left">
                          {order.testIds[0].name}
                          {order.testIds.length > 1 &&
                            ` +${order.testIds.length - 1} more`}
                        </span>
                      </TooltipTrigger>
                      <TooltipContent className={tooltipStyles} side="top">
                        <p>{order.testIds.map(t => t.name).join(', ')}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>

                <div className="flex gap-2">
                  <Button
                    size="sm"
                    onClick={() => handleWhatsAppShare(order)}
                    className="flex-1 bg-[#25D366] hover:bg-[#20b355] text-white"
                  >
                    <WhatsAppIcon className="h-4 w-4 mr-2" />
                    Share on WhatsApp
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => handleViewDetails(order)}
                    variant="orange"
                    className="flex-1"
                  >
                    View Details
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        ))}
        {tests.length === 0 && (
          <div className="text-center py-8">
            <p className="text-muted-foreground">No orders found.</p>
          </div>
        )}
      </div>

      {/* Dialog remains unchanged */}
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-2xl max-h-[85vh] overflow-y-auto mx-4 sm:mx-0 p-6">
          <DialogHeader className="space-y-3 pb-4 border-b">
            <DialogTitle className="text-xl font-semibold">Test Details</DialogTitle>
            <div className="flex flex-col gap-1">
              <div className="text-sm font-semibold">{selectedOrder?.patientName}</div>
              <div className="text-sm text-gray-600">{selectedOrder?.patientMobile}</div>
            </div>
          </DialogHeader>

          {selectedOrder && (
            <div className="mt-6 space-y-6">
              <div>
                <h3 className="text-lg font-semibold mb-3">Test Details</h3>
                <div className="space-y-4">
                  {selectedOrder.testIds.map((test) => (
                    <div
                      key={test._id}
                      className="p-4 border rounded-lg"
                    >
                      <div className="flex flex-col gap-2">
                        <div className="flex justify-between items-start">
                          <h4 className="font-medium">{test.name}</h4>
                          {test.assessment?.status==="COMPLETED" && (
                          <Link to={`/test-results/${test.assessment?._id}/${test.reportType}/${selectedOrder.patientGender}`}>
                              <Button
                                variant="outline"
                                size="sm"
                                className="flex items-center gap-2"
                              >
                                <FileText className="h-4 w-4" />
                                View Report
                              </Button>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Order Summary */}
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-lg font-semibold mb-3">Order Summary</h3>
                <div className="space-y-2">
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Status</span>
                    {renderTestStatus(selectedOrder.testCompleted, selectedOrder.totalTests)}
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Order Date</span>
                    <span>{formatDate(selectedOrder.orderedAt)}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>

  );
}
