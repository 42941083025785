import { FormEvent } from "react";
import { useCreateTeam, useTeams as useTeamsQuery } from "@/services/teamService";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";

export function useTeams() {
    const queryClient = useQueryClient();
    const { data: teams, isLoading } = useTeamsQuery();
    const createTeamMutation = useCreateTeam();

    const handleCreateTeam = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const name = formData.get("name") as string;
        const description = formData.get("description") as string;

        try {
            await createTeamMutation.mutateAsync({
                name,
                description
            });
            queryClient.invalidateQueries({ queryKey: ["teams"] });
            toast.success("Team created successfully!");
            (e.target as HTMLFormElement).reset();
        } catch (error) {
            toast.error("Failed to create team");
            console.error("Error creating team:", error);
        }
    };

    return {
        teams,
        isLoading,
        isCreating: createTeamMutation.isPending,
        createTeam: handleCreateTeam
    };
}
