import { useSubscriptionAccess } from "@/services/subscriptionService";

export function useMySubscriptions() {
    const { data: subscriptions, isLoading, error } = useSubscriptionAccess();

    return {
        subscriptions,
        isLoading,
        error
    };
}
