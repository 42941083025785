import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { ResizablePanel } from "@/components/ui/resizable";

export function SectionQuestionsSkeleton() {
  return (
    <div className="flex flex-col h-[calc(100vh-64px)]">
      {/* Sticky Header Skeleton */}
      <div className="sticky top-0 bg-white z-10 border-b">
        <div className="container mx-auto px-4 py-3">
          <div className="flex items-center justify-between mb-2">
            <div>
              <Skeleton className="h-6 w-48 mb-1" />
              <Skeleton className="h-4 w-64" />
            </div>
            <div className="text-right">
              <Skeleton className="h-4 w-12 mb-1" />
              <Skeleton className="h-4 w-16" />
            </div>
          </div>
          <Skeleton className="h-1.5 w-full" />
        </div>
      </div>

      <div className="flex-1 overflow-hidden">
        <div className="container mx-auto px-4 py-4">
          {/* Mobile Toggle Skeleton */}
          <div className="md:hidden flex space-x-2 mb-4">
            <Skeleton className="h-10 flex-1" />
            <Skeleton className="h-10 flex-1" />
          </div>

          <div className="hidden md:flex h-full gap-8">
            {/* Left side Skeleton */}
            <ResizablePanel defaultSize={40} minSize={30} maxSize={60}>
              <div className="h-full">
                <Card className="p-6">
                  <div className="space-y-2">
                    <Skeleton className="h-6 w-full mb-4" />
                    <Skeleton className="h-4 w-3/4" />
                    <Skeleton className="h-4 w-full" />
                    <Skeleton className="h-4 w-5/6" />
                    <Skeleton className="h-4 w-2/3" />
                    <Skeleton className="h-4 w-4/5" />
                    <Skeleton className="h-4 w-full" />
                  </div>
                </Card>
              </div>
            </ResizablePanel>

            {/* Right side Skeleton */}
            <div className="flex-1">
              <div className="space-y-4 pb-48">
                {[1, 2, 3].map((i) => (
                  <Card key={i}>
                    <CardHeader>
                      <Skeleton className="h-6 w-3/4" />
                    </CardHeader>
                    <CardContent>
                      <div className="space-y-4">
                        {[1, 2, 3].map((j) => (
                          <div key={j} className="flex items-center space-x-3">
                            <Skeleton className="h-4 w-4 rounded-full" />
                            <Skeleton className="h-12 w-12 rounded-md" />
                            <Skeleton className="h-4 flex-grow" />
                          </div>
                        ))}
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Navigation Skeleton */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg z-50">
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <Skeleton className="h-10 w-24" />
            <Skeleton className="h-10 w-24" />
          </div>
        </div>
      </div>
    </div>
  );
}
