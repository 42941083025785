import React from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import withLayout from '@/hoc/withLayout';
import GuestLayout from '@/layouts/GuestLayout';
import useLogin from './useLogin';
import { Card, CardHeader, CardContent } from '@/components/ui/card';
import {  LOG_IN_URL } from '@/constants';
import { Link } from 'react-router-dom';
import { Eye, EyeOff } from 'lucide-react';

const Login: React.FC = () => {
  const { email, setEmail, password, setPassword, errors, handleLogin, loading, showPassword, togglePassword } = useLogin();

  return (
    <div className="relative min-h-screen w-full">
      {/* Background container with image and gradient */}
      <div 
        className="absolute inset-0 bg-cover bg-center bg-no-repeat w-full h-full object-cover"
        style={{
          backgroundImage: `url(${LOG_IN_URL})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      />
      <div className="absolute inset-0 bg-gradient-to-b from-[#FDF6F4]/40 to-[#FDF6F4]/60" />

      {/* Content container */}
      <div className="relative flex flex-col items-center justify-center min-h-screen p-4">
        <Card className="w-full max-w-md shadow-2xl bg-white dark:bg-gray-900">
          <CardHeader className="text-center space-y-8 pt-8">
           
            <div className="flex justify-center">
              <img src={require('../../assets/logo.png')} alt="Logo" className="h-24 w-auto" />
            </div>
          </CardHeader>
          <CardContent className="space-y-8 px-8 pb-8">
            <div className="space-y-2">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Email
              </label>
              <Input
                id="email"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={errors.email ? 'border-red-500' : ''}
              />
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )}
            </div>
            <div className="space-y-2">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Password
              </label>
              <div className="relative">
                <Input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={errors.password ? 'border-red-500' : ''}
                />
                <button
                  type="button"
                  onClick={togglePassword}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                >
                  {showPassword ? <Eye size={20} />: <EyeOff size={20} />}
                </button>
              </div>
              {errors.password && (
                <p className="text-red-500 text-sm">{errors.password}</p>
              )}
            </div>

            <Button
              className="w-full"
              variant="orange"
              onClick={handleLogin}
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Login'}
            </Button>
            <hr className="my-4 border-gray-300 dark:border-gray-500" />
            <Link to="/register" className="block text-center text-gray-600 hover:text-gray-900">
            don't have an account? <strong>Register</strong>
              </Link>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default withLayout(GuestLayout)(Login);
