import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Skeleton } from "@/components/ui/skeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Textarea } from "@/components/ui/Textarea";
import { useState } from "react";
import { ArrowLeft, Loader2, Settings } from "lucide-react";
import { useTeamDetails } from "./useTeamDetails";
import { MembersTab } from "./components/MembersTab";
import { AccessCodesTab } from "./components/AccessCodesTab";
import { SubscriptionsTab } from "./components/SubscriptionsTab";
import { useNavigate } from "react-router-dom";
import withLayout from "@/hoc/withLayout";
import GeneralUserLayout from "@/layouts/GeneralUser/GeneralUserLayout";

function TeamDetailsPage() {
    const {
        team,
        accessCodes,
        isLoading,
        createAccessCode,
        toggleAccessCode,
        addMember,
        removeMember,
        copyToClipboard,
        updateTeam,
        isUpdating,
        isAddingMember,
        isCreatingCode,
        members,
        isRemovingMember
    } = useTeamDetails();
    const navigate = useNavigate();

    const [settingsOpen, setSettingsOpen] = useState(false);

    const handleUpdateTeam = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await updateTeam(e);
            setSettingsOpen(false);
        } catch (error) {
            console.error('Failed to update team:', error);
        }
    };

    if (isLoading) {
        return (
            <div className="space-y-4 p-4 md:p-8">
                <div className="flex items-center space-x-4">
                    <Skeleton className="h-9 w-9" />
                    <div className="space-y-2">
                        <Skeleton className="h-8 w-[200px]" />
                        <Skeleton className="h-4 w-[300px]" />
                    </div>
                </div>
                <Skeleton className="h-[400px]" />
            </div>
        );
    }

    return (
        <div className="space-y-6 p-4 md:p-8">
            <div className="flex flex-col md:flex-row justify-between md:items-start gap-4">
                <div className="flex items-start space-x-4 flex-1">
                    <Button 
                        variant="ghost" 
                        size="icon" 
                        onClick={() => navigate('/teams')}
                        className="mt-1 shrink-0"
                    >
                        <ArrowLeft className="h-4 w-4" />
                    </Button>
                    <div className="min-h-[2.5rem] flex flex-col justify-center">
                        <h2 className="text-xl md:text-2xl font-bold">{team?.name}</h2>
                        {team?.description && (
                            <p className="text-muted-foreground mt-1">{team.description}</p>
                        )}
                    </div>
                </div>
                <Button 
                    variant="outline" 
                    size="icon" 
                    onClick={() => setSettingsOpen(true)}
                    className="shrink-0 ml-auto md:ml-0"
                >
                    <Settings className="h-4 w-4" />
                </Button>
            </div>

            <Tabs defaultValue="members" className="space-y-6">
                <div className="border-b overflow-x-auto">
                    <TabsList className="w-full justify-start">
                        <TabsTrigger value="members">Members</TabsTrigger>
                        <TabsTrigger value="access-codes">Access Codes</TabsTrigger>
                        <TabsTrigger value="subscriptions">Subscriptions</TabsTrigger>
                    </TabsList>
                </div>

                <TabsContent value="members" className="mt-0">
                    <MembersTab
                        members={members || []}
                        isAddingMember={isAddingMember}
                        isRemovingMember={isRemovingMember}
                        addMember={addMember}
                        removeMember={removeMember}
                        team={team!}
                    />
                </TabsContent>

                <TabsContent value="access-codes" className="mt-0">
                    <AccessCodesTab
                        accessCodes={accessCodes || []}
                        isCreatingCode={isCreatingCode}
                        createAccessCode={createAccessCode}
                        toggleAccessCode={toggleAccessCode}
                        copyToClipboard={copyToClipboard}
                        team={team!}
                    />
                </TabsContent>

                <TabsContent value="subscriptions" className="mt-0">
                    <SubscriptionsTab />
                </TabsContent>
            </Tabs>

            <Dialog open={settingsOpen} onOpenChange={setSettingsOpen}>
                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>Team Settings</DialogTitle>
                        <DialogDescription>
                            Update your team's information.
                        </DialogDescription>
                    </DialogHeader>
                    <form onSubmit={handleUpdateTeam} className="space-y-4">
                        <div className="grid gap-2">
                            <Label htmlFor="name">Team Name</Label>
                            <Input
                                id="name"
                                name="name"
                                defaultValue={team?.name}
                                required
                                placeholder="Enter team name"
                            />
                        </div>
                        <div className="grid gap-2">
                            <Label htmlFor="description">Description</Label>
                            <Textarea
                                id="description"
                                name="description"
                                defaultValue={team?.description}
                                placeholder="Enter team description"
                                className="resize-none"
                                rows={3}
                            />
                        </div>
                        <DialogFooter>
                            <Button 
                                type="button" 
                                variant="outline" 
                                onClick={() => setSettingsOpen(false)}
                                disabled={isUpdating}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" variant="orange" disabled={isUpdating}>
                                {isUpdating && <Loader2 className="w-4 h-4 mr-2 animate-spin" />}
                                Save Changes
                            </Button>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default withLayout(GeneralUserLayout)(TeamDetailsPage);