import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Skeleton } from "@/components/ui/skeleton";
import { Textarea } from "@/components/ui/Textarea";
import { useTeams } from "./useTeams";
import { Loader2, Plus, Users } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import withLayout from "@/hoc/withLayout";
import GeneralUserLayout from "@/layouts/GeneralUser/GeneralUserLayout";

function TeamsPage() {
    const { teams, isLoading, createTeam, isCreating } = useTeams();
    const navigate = useNavigate();

    if (isLoading && !isCreating) {
        return (
            <div className="container mx-auto p-8">
                <div className="flex justify-between items-center mb-8">
                    <div>
                        <Skeleton className="h-8 w-32 mb-2" />
                        <Skeleton className="h-4 w-64" />
                    </div>
                    <Skeleton className="h-10 w-32" />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {[1, 2, 3].map((i) => (
                        <Card key={i} className="border border-muted">
                            <CardHeader>
                                <Skeleton className="h-6 w-3/4 mb-2" />
                                <Skeleton className="h-4 w-full" />
                            </CardHeader>
                            <CardContent>
                                <Skeleton className="h-4 w-24" />
                            </CardContent>
                            <CardFooter>
                                <Skeleton className="h-9 w-full" />
                            </CardFooter>
                        </Card>
                    ))}
                </div>
            </div>
        );
    }

    const hasTeams = teams && teams.length > 0;

    return (
        <div className="container mx-auto p-8">
            <div className="flex justify-between items-center mb-8">
                <div>
                    <h1 className="text-2xl font-bold mb-2">Teams</h1>
                    <p className="text-muted-foreground">Manage your teams and team members</p>
                </div>
                <CreateTeamDialog onSubmit={createTeam} isCreating={isCreating} />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {hasTeams ? (
                    teams.map((team) => (
                        <Card key={team._id} className="border border-muted hover:border-primary/20 transition-colors">
                            <CardHeader className="space-y-2">
                                <CardTitle className="text-lg">{team.name}</CardTitle>
                                {team.description && (
                                    <CardDescription className="line-clamp-2">{team.description}</CardDescription>
                                )}
                            </CardHeader>
                            <CardContent>
                                <div className="flex items-center text-sm text-muted-foreground">
                                    <Users className="w-4 h-4 mr-2" />
                                    {team.membersCount || 0} members
                                </div>
                            </CardContent>
                            <CardFooter>
                                <Button 
                                    variant="outline" 
                                    className="w-full hover:bg-primary/5" 
                                    onClick={() => navigate(`/teams/${team._id}`)}
                                >
                                    View Team
                                </Button>
                            </CardFooter>
                        </Card>
                    ))
                ) : (
                    <div className="col-span-full flex flex-col items-center justify-center py-12 border-2 border-dashed border-muted rounded-lg">
                        <Users className="w-12 h-12 text-muted-foreground mb-4" />
                        <h3 className="text-lg font-medium mb-2">No Teams Yet</h3>
                        <p className="text-muted-foreground mb-4 text-center max-w-sm">Create your first team to start collaborating with others</p>
                        <CreateTeamDialog onSubmit={createTeam} isCreating={isCreating} />
                    </div>
                )}
            </div>
        </div>
    );
}

interface CreateTeamDialogProps {
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    isCreating: boolean;
}

function CreateTeamDialog({ onSubmit, isCreating }: CreateTeamDialogProps) {
    const [open, setOpen] = useState(false);
    
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await onSubmit(e);
        setOpen(false);
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="orange" onClick={() => setOpen(true)}>
                    <Plus className="w-4 h-4 mr-2" />
                    Create Team
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Create New Team</DialogTitle>
                    <DialogDescription>
                        Create a new team to collaborate with others
                    </DialogDescription>
                </DialogHeader>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="space-y-2">
                        <Label htmlFor="name">Team Name</Label>
                        <Input id="name" name="name" required placeholder="Enter team name" />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="description">Description</Label>
                        <Textarea
                            id="description"
                            name="description"
                            placeholder="Enter team description"
                            className="resize-none"
                            rows={3}
                        />
                    </div>
                    <DialogFooter>
                        <Button type="button" variant="outline" onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="orange" disabled={isCreating}>
                            {isCreating && <Loader2 className="w-4 h-4 mr-2 animate-spin" />}
                            Create Team
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
}


export default withLayout(GeneralUserLayout)(TeamsPage)