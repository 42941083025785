import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBook, useCreatePurchase, useVerifyPayment } from '@/services/bookService';
import { useAuth } from '@/contexts/AuthContext';
import { toast } from 'sonner';

export function useBookDetails() {
    const { bookId = '' } = useParams();
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);

    const { data: book, isLoading, refetch } = useBook(bookId);
    const createPurchase = useCreatePurchase();
    const verifyPayment = useVerifyPayment();
    const { user } = useAuth();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handlePurchase = async () => {
        if (!bookId) return;

        try {
            setIsProcessingPayment(true);
            setIsPaymentModalOpen(false);

            const orderData = await createPurchase.mutateAsync(bookId);

            //Test key
            const razorpayKey = "rzp_test_4MtJ3MvwsXU49D";

            //Live key
            // const razorpayKey = "rzp_live_o4KCP1IOUjxtDX";
            // If we get paymentOrder, it means it's a paid book
            if (orderData.paymentOrder) {
                const options = {
                    key: razorpayKey, // Replace with your Razorpay key
                    amount: orderData.paymentOrder.amount,
                    currency: orderData.paymentOrder.currency,
                    name: 'Vedita AI',
                    description: `Purchase ${book?.title}`,
                    order_id: orderData.paymentOrder.id,
                    handler: async (response: any) => {
                        const verifyData = await verifyPayment.mutateAsync({
                            bookId: bookId,
                            paymentData: {
                                razorpay_payment_id: response.razorpay_payment_id,
                                razorpay_order_id: response.razorpay_order_id,
                                razorpay_signature: response.razorpay_signature
                            }
                        });
                        if (verifyData.status === 'SUCCESS') {
                            refetch()
                        } else {
                            toast.error("Payment verification failed", verifyData);
                        }
                        try {
                            // After successful payment, refresh to update purchase status
                            refetch();
                        } catch (error) {
                            toast.error("Payment verification failed:");
                        } finally {
                            setIsProcessingPayment(false);
                        }
                    },
                    modal: {
                        ondismiss: () => {
                            setIsProcessingPayment(false);
                        }
                    },
                    prefill: {
                        name: user?.name,
                        email: user?.email,
                        contact: user?.mobile
                    },
                    theme: {
                        color: '#7C3AED' // Primary color
                    }
                };

                const rzp = new (window as any).Razorpay(options);
                rzp.open();
            } else {
                refetch();
                setIsProcessingPayment(false);
            }
        } catch (error) {
            console.error('Purchase failed:', error);
            setIsProcessingPayment(false);
            setIsPaymentModalOpen(true);
        }
    };

    return {
        book,
        isLoading,
        isPaymentModalOpen,
        setIsPaymentModalOpen,
        isProcessingPayment,
        handlePurchase,
        isPurchased: book?.purchased ?? false,
    };
} 