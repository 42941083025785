import { FormEvent } from "react";
import { useParams } from "react-router-dom";
import { 
    useTeamDetails as useTeamDetailsQuery, 
    useTeamAccessCodes, 
    useCreateTeamAccessCode, 
    useAddTeamMember, 
    useRemoveTeamMember, 
    useUpdateMemberStatus,
    useToggleAccessCode,
    useUpdateTeam,
    useTeamMembers
} from "@/services/teamService";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";

export function useTeamDetails() {
    const { teamId } = useParams<{ teamId: string }>();
    const queryClient = useQueryClient();

    const { data: team, isLoading: isLoadingTeam } = useTeamDetailsQuery(teamId!);
    const { data: members, isLoading: isLoadingMembers } = useTeamMembers(teamId!);
    const { data: accessCodes, isLoading: isLoadingCodes } = useTeamAccessCodes(teamId!);
    const createAccessCodeMutation = useCreateTeamAccessCode(teamId!);
    const addMemberMutation = useAddTeamMember(teamId!);
    const removeMemberMutation = useRemoveTeamMember(teamId!);
    const updateMemberStatusMutation = useUpdateMemberStatus(teamId!);
    const toggleAccessCodeMutation = useToggleAccessCode(teamId!);
    const updateTeamMutation = useUpdateTeam(teamId!);

    const handleCreateAccessCode = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const maxUses = formData.get("maxUses") as string;
        const expiresAt = formData.get("expiresAt") as string;

        try {
            await createAccessCodeMutation.mutateAsync({
                maxUses: maxUses ? parseInt(maxUses) : undefined,
                expiresAt: expiresAt || undefined
            });
            queryClient.invalidateQueries({ queryKey: ["teams", teamId, "access-codes"] });
            toast.success("Access code created successfully!");
            (e.target as HTMLFormElement).reset();
        } catch (error) {
            toast.error("Failed to create access code");
            console.error("Error creating access code:", error);
        }
    };

    const handleToggleAccessCode = async (code: string) => {
        try {
            await toggleAccessCodeMutation.mutateAsync(code);
            queryClient.invalidateQueries({ queryKey: ["teams", teamId, "access-codes"] });
            toast.success("Access code status updated successfully!");
        } catch (error) {
            toast.error("Failed to update access code status");
            console.error("Error updating access code status:", error);
        }
    };

    const handleUpdateTeam = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const name = formData.get("name") as string;
        const description = formData.get("description") as string;

        try {
            await updateTeamMutation.mutateAsync({ name, description });
            queryClient.invalidateQueries({ queryKey: ["teams", teamId] });
            toast.success("Team updated successfully!");
            (e.target as HTMLFormElement).reset();
        } catch (error: any) {
            toast.error(error?.response?.data?.message || "Failed to update team");
            console.error("Error updating team:", error);
        }
    };

    const handleAddMember = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const email = formData.get("email") as string;
        const mobile = formData.get("mobile") as string;
        const countryCode = formData.get("countryCode") as string;
        const role = formData.get("role") as string;

        // Validate that either email or both mobile and country code are provided
        if (!email && (!mobile || !countryCode)) {
            toast.error("Please provide either email or both mobile number and country code");
            return;
        }

        try {
            await addMemberMutation.mutateAsync({
                email: email || undefined,
                mobile: mobile || undefined,
                countryCode: countryCode || undefined,
                isAdmin: role === "admin"
            });
            queryClient.invalidateQueries({ queryKey: ["teams", teamId, "members"] });
            toast.success("Member added successfully!");
        } catch (error: any) {
            toast.error(error?.response?.data?.message || "Failed to add member");
            console.error("Error adding member:", error);
        }
    };

    const handleRemoveMember = async (userId: string) => {
        try {
            await removeMemberMutation.mutateAsync(userId);
            queryClient.invalidateQueries({ queryKey: ["teams", teamId, "members"] });
            toast.success("Member removed successfully!");
        } catch (error: any) {
            toast.error(error?.response?.data?.message || "Failed to remove member");
            console.error("Error removing member:", error);
        }
    };

    const handleUpdateMemberStatus = async (userId: string, status: string) => {
        try {
            await updateMemberStatusMutation.mutateAsync({ userId, status });
            queryClient.invalidateQueries({ queryKey: ["teams", teamId, "members"] });
            toast.success("Member status updated successfully!");
        } catch (error: any) {
            toast.error(error?.response?.data?.message || "Failed to update member status");
            console.error("Error updating member status:", error);
        }
    };

    const handleCopyToClipboard = (code: string) => {
        navigator.clipboard.writeText(code);
        toast.success("Code copied to clipboard!");
    };

    return {
        team,
        members,
        accessCodes,
        isLoading: isLoadingTeam || isLoadingCodes || isLoadingMembers,
        createAccessCode: handleCreateAccessCode,
        toggleAccessCode: handleToggleAccessCode,
        addMember: handleAddMember,
        removeMember: handleRemoveMember,
        updateMemberStatus: handleUpdateMemberStatus,
        copyToClipboard: handleCopyToClipboard,
        updateTeam: handleUpdateTeam,
        isUpdating: updateTeamMutation.isPending,
        isAddingMember: addMemberMutation.isPending,
        isCreatingCode: createAccessCodeMutation.isPending,
        isRemovingMember: removeMemberMutation.isPending,
        isUpdatingMemberStatus: updateMemberStatusMutation.isPending,
        isTogglingAccessCode: toggleAccessCodeMutation.isPending
    };
}
