import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

export const DeleteAccount: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto">
        <Link to="/" className="inline-flex items-center text-sm text-muted-foreground hover:text-primary mb-4">
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Home
        </Link>
        <Card className="w-full h-full">
          <CardHeader>
            <CardTitle className="text-3xl font-bold mb-6">Account Deletion Policy</CardTitle>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-full w-full pr-4">
              <div className="space-y-6">
                <section>
                  <p className="text-sm text-muted-foreground">
                    At VEDITA.AI, we respect your right to data privacy and control over your personal information. 
                    This page explains our account deletion process and what happens to your data when you request 
                    account deletion.
                  </p>
                </section>

                <section>
                  <h3 className="text-lg font-semibold mb-2">How to Request Account Deletion</h3>
                  <p className="text-sm text-muted-foreground">
                    To initiate the account deletion process, please send an email to support@vedita.ai with the 
                    subject line "Account Deletion Request". Include your registered email address in the email body 
                    for verification purposes.
                  </p>
                </section>

                <section>
                  <h3 className="text-lg font-semibold mb-2">Deletion Process Timeline</h3>
                  <p className="text-sm text-muted-foreground">
                    Once we receive your account deletion request:
                  </p>
                  <ul className="list-disc pl-5 mt-2 space-y-2 text-sm text-muted-foreground">
                    <li>We will confirm receipt of your request within 2 business days</li>
                    <li>Your account will be marked for deletion and deactivated</li>
                    <li>All your personal data will be permanently deleted within 90 days</li>
                  </ul>
                </section>

                <section>
                  <h3 className="text-lg font-semibold mb-2">What Data Gets Deleted</h3>
                  <p className="text-sm text-muted-foreground">
                    The deletion process includes removal of:
                  </p>
                  <ul className="list-disc pl-5 mt-2 space-y-2 text-sm text-muted-foreground">
                    <li>Your account profile and personal information</li>
                    <li>Your activity history and preferences</li>
                    <li>Any content you've created or uploaded</li>
                    <li>Communication history and support tickets</li>
                  </ul>
                </section>

                <section>
                  <h3 className="text-lg font-semibold mb-2">Important Notes</h3>
                  <ul className="list-disc pl-5 mt-2 space-y-2 text-sm text-muted-foreground">
                    <li>Account deletion is permanent and cannot be undone</li>
                    <li>Any active subscriptions should be cancelled before requesting deletion</li>
                    <li>Data required for legal or regulatory compliance may be retained as required by law</li>
                    <li>Backup copies of your data may take up to 90 days to be completely removed from our systems</li>
                  </ul>
                </section>

                <section>
                  <h3 className="text-lg font-semibold mb-2">Questions?</h3>
                  <p className="text-sm text-muted-foreground">
                    If you have any questions about the account deletion process, please contact our support team 
                    at support@vedita.ai.
                  </p>
                </section>
              </div>
            </ScrollArea>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
