import { useAssessmentService } from "@/services/Patient/assessmentService";
import { useNavigate, useParams } from "react-router-dom";

const enum AssessmentType {
  VIKRITI = "Vikriti",
  VIKRITI_LIGHT = "Vikriti_Light",
  PRAKRITI = "Prakriti",
  MANAS = "Manas",
  TONGUE_ANALYSIS = "Tongue_Analysis",
}

export const useAssessment = () => {
  const navigate = useNavigate();

  const { assessmentId } = useParams();
  const { useGetAssessment } = useAssessmentService();

  const { data: assessmentData, isLoading: isAssessmentLoading } =
    useGetAssessment(assessmentId || "");
  const assessment = assessmentData?.data?.assessment;
  const progress = assessment?.progress;
  const startTest = () => {
    if (
      assessmentId &&
      assessmentData?.data.assessment.testId.reportType ===
        AssessmentType.TONGUE_ANALYSIS
    ) {
      navigate(`/patient/tongue-analysis/${assessmentId}`);
    } else {
      navigate(`/patient/test/resume/${assessmentId}`);
    }
  };

  return {
    assessment,
    progress,
    isLoading: isAssessmentLoading,
    startTest,
  };
};
