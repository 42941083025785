import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { CheckCircle2, Clock, FileText } from "lucide-react";
import { DashboardStats } from "@/types/patient/dashboard";

export function TestStatus({ totalTests, inProgressTests, completedTests }: DashboardStats) {
  const completionPercentage = totalTests === 0 ? 0 : (completedTests / totalTests) * 100;
  const inProgressPercentage = totalTests - completedTests === 0 ? 0 : (inProgressTests / (totalTests - completedTests)) * 100;

  return (
    <Card className="hover:shadow-lg transition-shadow duration-300">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 px-6 pt-6">
        <div className="space-y-1">
          <CardTitle className="text-xl font-bold">Test Status</CardTitle>
          <p className="text-sm text-muted-foreground">Your test completion overview</p>
        </div>
        <div className="h-12 w-12 rounded-full bg-blue-100 flex items-center justify-center">
          <FileText className="h-6 w-6 text-blue-700" />
        </div>
      </CardHeader>
      <CardContent className="px-6 pb-6 space-y-6">
        {/* Stats Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <div className="p-4 bg-gray-50 rounded-lg space-y-1">
            <div className="flex items-center justify-between sm:block">
              <p className="text-2xl font-bold">{totalTests}</p>
              <p className="text-sm sm:text-xs text-muted-foreground">Total Tests</p>
            </div>
          </div>
          <div className="p-4 bg-green-50 rounded-lg space-y-1">
            <div className="flex items-center justify-between sm:block">
              <p className="text-2xl font-bold text-green-600">{completedTests}</p>
              <p className="text-sm sm:text-xs text-muted-foreground">Completed</p>
            </div>
          </div>
          <div className="p-4 bg-yellow-50 rounded-lg space-y-1">
            <div className="flex items-center justify-between sm:block">
              <p className="text-2xl font-bold text-yellow-600">{inProgressTests}</p>
              <p className="text-sm sm:text-xs text-muted-foreground">In Progress</p>
            </div>
          </div>
        </div>

        {/* Progress Bars Section */}
        <div className="space-y-4">
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <CheckCircle2 className="w-4 h-4 text-green-600" />
                <span className="text-sm font-medium">Completed</span>
              </div>
              <span className="text-sm font-medium">{completedTests} of {totalTests}</span>
            </div>
            <div className="relative">
              <Progress
                value={completionPercentage}
                className="h-2 bg-gray-100"
                indicatorClassName="bg-green-600"
              />
              <span className="absolute right-0 -top-1 text-xs text-green-600 font-medium">
                {Math.round(completionPercentage)}%
              </span>
            </div>
          </div>

          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <Clock className="w-4 h-4 text-yellow-600" />
                <span className="text-sm font-medium">In Progress</span>
              </div>
              <span className="text-sm font-medium">{inProgressTests} of {totalTests - completedTests}</span>
            </div>
            <div className="relative">
              <Progress
                value={inProgressPercentage}
                className="h-2 bg-gray-100"
                indicatorClassName="bg-yellow-600"
              />
              <span className="absolute right-0 -top-1 text-xs text-yellow-600 font-medium">
                {Math.round(inProgressPercentage)}%
              </span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
