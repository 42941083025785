import { useState } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { useApi } from '@/hooks/useApi';
import { handleApiErrors } from '@/utils/toolkit';
import { useMutation } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { Errors } from '@/types/Unauth/unAuth';



const useRegister = () => {
  const [searchParams] = useSearchParams();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [countryCode, setCountryCode] = useState('+91');
  const [role, setRole] = useState(searchParams.get('role')?.toUpperCase() || 'GENERAL');
  const [gender, setGender] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [otpToken, setOtpToken] = useState();
  const [otpError, setOtpError] = useState('');
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState('');
  const [phone, setPhone] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const { setAuthInfo } = useAuth();
  const api = useApi();

  const validateForm = () => {
    const newErrors: typeof errors = {};

    if (!name) newErrors.name = 'Name is required';
    if (!email) newErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = 'Email is invalid';
    if (!password) newErrors.password = 'Password is required';
    else if (password.length < 6) newErrors.password = 'Password must be at least 6 characters';
    if (!mobile) newErrors.mobile = 'Mobile number is required';
    if (!role) newErrors.role = 'Role is required';
    if (!gender) newErrors.gender = 'Gender is required';
    if (!termsAccepted) newErrors.terms = 'You must accept the terms and conditions';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePhoneChange = (value: string, data: any) => {
    setPhone(value);
    setMobile(value.slice(data.dialCode.length));
    setCountryCode(`+${data.dialCode}`);

  };

  const handleOtpChange = (value: string) => {
    setOtp(value); 
  };
  
  const resendOtpMutation = useMutation({
    mutationFn: async (otpToken: string) => {
      const response = await api.post(`/auth/resend-otp?otpToken=${otpToken}`, {
        otpToken: `${otpToken}`
      });
      return response.data;
    },
    onError: (error) => {
      handleApiErrors(error);
    }
  });

  const handleSendOtp = async () => {
    if (!phone) {
      return false;
    }
    
    try {
      resendOtpMutation.mutate(`${otpToken}`);
      return true;
    } catch {
      return false;
    }
  };

  const verifyOtpMutation = useMutation({
    mutationFn: async (data: { otpToken: string; otp: string }) => {
      const response = await api.post('/auth/verify-otp', data);
      return response.data;
    },
    onSuccess: (data) => {
      setShowOtpModal(false);
      setOtp('');
      const { token, refreshToken, data: userData } = data;
      setAuthInfo(token, refreshToken, userData);
    },
    onError: () => {
      setOtpError('Invalid OTP. Please try again.');
    }
  });

  const handleVerifyOtp = async () => {
    setOtpError('');

    if (otp.length !== 6) {
      setOtpError('Please enter a valid 6-digit OTP');
      return;
    }

    verifyOtpMutation.mutate({
      otpToken: `${otpToken}`,
      otp
    });
  };

  const registerMutation = useMutation({
    mutationFn: async (data: {
      name: string;
      email: string;
      password: string;
      mobile: string;
      countryCode: string;
      role: string;
      gender: string;
      terms: boolean;
    }) => {
      const response = await api.post('/auth/register', data);
      return response.data;
    },
    onSuccess: (data) => {
      setShowOtpModal(true);
      setOtpToken(data.otpToken);
    },
    onError: (error) => {
      handleApiErrors(error);
    }
  });
  
  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!validateForm()) {
      return;
    }
  
    registerMutation.mutate({
      name,
      email,
      password,
      mobile,
      countryCode,
      role,
      gender,
      terms:termsAccepted
    });
  };

  const togglePassword = () => setShowPassword(prev => !prev);

  return {
    name,
    setName,
    email,
    setEmail,
    password,
    setPassword,
    phone,
    role,
    setRole,
    gender,
    setGender,
    termsAccepted,
    setTermsAccepted,
    handlePhoneChange,
    errors,
    setErrors,
    handleRegister,
    loading: registerMutation.isPending,
    handleSendOtp,
    handleVerifyOtp,
    verifyLoading: verifyOtpMutation.isPending,
    resendOtpLoading: resendOtpMutation.isPending,
    otpError,
    otp,
    handleOtpChange,
    otpToken,
    showOtpModal,
    setShowOtpModal,
    showPassword,
    togglePassword
  };
};

export default useRegister;