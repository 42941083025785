import React from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import withLayout from '@/hoc/withLayout';
import GuestLayout from '@/layouts/GuestLayout';
import useRegister from './useRegister';
import { Link, useLocation } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import {  LOG_IN_URL } from "@/constants";
import { Eye, EyeOff } from 'lucide-react';

const Register: React.FC = () => {
  const {
    name,
    setName,
    email,
    setEmail,
    password,
    setPassword,
    phone,
    role,
    setRole,
    gender,
    setGender,
    termsAccepted,
    setTermsAccepted,
    handlePhoneChange,
    errors,
    handleRegister,
    loading,
    handleSendOtp,
    handleVerifyOtp,
    verifyLoading,
    otpError,
    otp,
    handleOtpChange,
    showOtpModal,
    setShowOtpModal,
    showPassword,
    togglePassword,
    setErrors
  } = useRegister();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isSignupEnabled = queryParams.get('enable_signup') === 'true';

  return (
    <div className="relative min-h-screen w-full">
      {/* Background container with image and gradient */}
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${LOG_IN_URL})`,
        }}
      />
      <div className="absolute inset-0 bg-gradient-to-b from-[#FDF6F4]/40 to-[#FDF6F4]/60" />

      {/* Content container */}
      <div className="relative flex flex-col items-center justify-center min-h-screen p-4">
        <Card className="w-full max-w-md shadow-2xl bg-white dark:bg-gray-900">
          <CardHeader className="text-center space-y-8 pt-8">
            <div className="flex justify-center">
              
              <img src={require('../../assets/logo.png')} alt="Logo" className="h-24 w-auto" />
            </div>
          </CardHeader>
          <CardContent className="space-y-6 px-8 pb-8">
            <div className="w-full space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                  Full Name
                </label>
                <Input
                  id="name"
                  type="text"
                  placeholder="Enter your full name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className={errors.name ? 'border-red-500' : ''}
                />
                {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                  Email Address
                </label>
                <Input
                  id="email"
                  type="email"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={errors.email ? 'border-red-500' : ''}
                />
                {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
              </div>

              <div className="relative">
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                  Mobile Number
                </label>
                <style>
                  {`
  .phone-input-container {
    position: relative;
    width: 100%;
  }
  .phone-input-container .react-tel-input {
    width: 100%;
  }
  .phone-input-container .form-control {
    width: 100% !important;
    height: 40px !important;
    padding-right: 84px !important;
  }
  .phone-input-container .form-control:focus {
    outline: none !important;
    border-color: var(--primary) !important;
  }
  .phone-input-container .form-control:focus-visible {
    outline: none !important;
    ring: 1px var(--primary) !important;
  }
  .phone-input-container .flag-dropdown {
    border: none !important;
    background: transparent !important;
  }
  .phone-input-container .flag-dropdown.open {
    background: white !important;
  }
  .phone-input-container .selected-flag {
    background: transparent !important;
  }
  .phone-input-container .country-list {
    width: 280px !important;
  }
  @media (min-width: 768px) {
    .phone-input-container .country-list {
      width: 320px !important;
    }
  }
`}
                </style>
                <div className="phone-input-container">
                  <PhoneInput
                    country={'in'}
                    value={phone}
                    onChange={handlePhoneChange}
                    inputClass={errors.mobile ? 'border-red-500' : 'border-gray-300'}
                    containerClass="w-full"
                    enableSearch={true}
                    disableSearchIcon={true}
                    specialLabel=""
                  />
                </div>
                {errors.mobile && <p className="text-red-500 text-sm mt-1">{errors.mobile}</p>}
              </div>

              <div>
                <label htmlFor="gender" className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                  Gender
                </label>
                <div style={{ position: 'relative', width: '100%' }}>
                  <Select value={gender} onValueChange={setGender}>
                    <SelectTrigger 
                      id="gender" 
                      style={{
                        width: '100%',
                        transition: 'all 0.2s ease-in-out',
                      }}
                      className={`${errors.gender ? 'border-red-500' : ''} focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[var(--primary)] focus:border-[var(--primary)]`}
                    >
                      <SelectValue placeholder="Select your gender" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="MALE">Male</SelectItem>
                      <SelectItem value="FEMALE">Female</SelectItem>
                      <SelectItem value="OTHER">Other</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                {errors.gender && <p className="text-red-500 text-sm mt-1">{errors.gender}</p>}
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                  Password
                </label>
                <div className="relative">
                  <Input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={errors.password ? 'border-red-500' : ''}
                  />
                  <button
                  type="button"
                  onClick={togglePassword}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                >
                  {showPassword ?   <Eye size={20} />:<EyeOff size={20} />}
                </button>
                </div>
                {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
              </div>

              

              <div className="flex items-center space-x-2">
                <Checkbox 
                  id="terms" 
                  checked={termsAccepted}
                  onCheckedChange={(checked) => {
                    if (typeof checked === 'boolean') {
                      setTermsAccepted(checked);
                      // Clear the terms error when checkbox is checked
                      if (checked && errors.terms) {
                        setErrors(prev => ({ ...prev, terms: undefined }));
                      }
                    }
                  }}
                  className={`${errors.terms ? 'border-red-500' : ''} cursor-pointer data-[state=checked]:bg-[var(--primary)] data-[state=checked]:border-[var(--primary)]`}
                />
                <label
                  htmlFor="terms"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer select-none"
                >
                  I agree to the{' '}
                  <a href="/terms-of-use" className="text-primary hover:underline" target="_blank" rel="noopener noreferrer">
                    Terms of Service
                  </a>
                  {' '}and{' '}
                  <a href="/privacy-policy" className="text-primary hover:underline" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                </label>
              </div>
              {errors.terms && <p className="text-red-500 text-sm mt-1">{errors.terms}</p>}

              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleRegister(e);
                }}
                disabled={loading}
                className="w-full"
                variant="orange"
                type="button"
              >
                {loading ? 'Loading...' : 'Register'}
              </Button>
              <hr className="my-4 border-gray-600 dark:border-gray-600" />
              <Link to="/login" className="block text-center text-gray-600 hover:text-gray-900">
                Already have an account? <strong>Login</strong>
              </Link>
            </div>
          </CardContent>
        </Card>
      </div>

      <Dialog open={showOtpModal} onOpenChange={setShowOtpModal}>
        <DialogContent
          className="sm:max-w-[425px] bg-white dark:bg-[#2F2F2F]"
          onPointerDownOutside={(e) => {
            e.preventDefault();
          }}
          showClose={false}
        >
          <DialogHeader>
            <DialogTitle className="text-gray-900 dark:text-white">Enter OTP</DialogTitle>
            <DialogDescription className="text-gray-900 dark:text-white">
              Please enter the OTP sent to your email {email}
            </DialogDescription>
          </DialogHeader>
          <div className="mt-4 space-y-6">
            <OtpInput
              value={otp}
              onChange={handleOtpChange}
              numInputs={6}
              renderInput={(props) => (
                <input
                  {...props}
                  className="h-12 w-12 text-center border rounded-md mx-1 focus:border-primary focus:outline-none dark:bg-background"
                  type="text"
                />
              )}
              shouldAutoFocus
              inputStyle={{
                width: '100%',
              }}
              containerStyle="flex justify-center gap-2"
            />
            {otpError && (
              <p className="text-red-500 text-sm mt-2 text-center">{otpError}</p>
            )}

            <div className="flex flex-col items-center gap-4">
              <Button
                onClick={handleVerifyOtp}
                disabled={verifyLoading}
                variant="orange"
              >
                {verifyLoading ? 'Verifying...' : 'Verify OTP'}
              </Button>

              <Button
                variant="outline"
                onClick={handleSendOtp}
                type="button"
                className="text-sm"
              >
                Resend OTP
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default withLayout(GuestLayout)(Register);