import { useTeamSubscriptions } from "@/services/subscriptionService";
import { Subscription } from "@/types/subscription";

export function useSubscriptionsTab(teamId: string | undefined) {
    const { data: teamSubscriptions = [], isLoading } = useTeamSubscriptions(teamId);


    return {
        teamSubscriptions,
        isLoading
    };
}
