import { useMutation, useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';
import { TeamAccessCode, TeamMember, TeamResponse } from "@/types/team";


// Get user's teams
export const useTeams = () => {
    const api = useApi();
    return useQuery({
        queryKey: ['teams'],
        queryFn: async () => {
            const { data } = await api.get('/teams');
            return data as TeamResponse[];
        }
    });
};

// Get specific team details
export const useTeamDetails = (teamId: string) => {
    const api = useApi();
    return useQuery({
        queryKey: ['teams', teamId],
        queryFn: async () => {
            const { data } = await api.get(`/teams/${teamId}`);
            return data as TeamResponse;
        },
        enabled: !!teamId
    });
};

// Create team
export const useCreateTeam = () => {
    const api = useApi();
    return useMutation({
        mutationFn: async (teamData: { name: string; description?: string; logo?: string }) => {
            const { data } = await api.post('/teams', teamData);
            return data as TeamResponse;
        }
    });
};

// Update team settings
export const useUpdateTeamSettings = (teamId: string) => {
    const api = useApi();
    return useMutation({
        mutationFn: async (data: { name: string; description?: string }) => {
            const { data: response } = await api.put(`/teams/${teamId}`, data);
            return response as TeamResponse;
        }
    });
};

// Update team
export const useUpdateTeam = (teamId: string) => {
    const api = useApi();
    return useMutation({
        mutationFn: async (teamData: { name?: string; description?: string; logo?: string }) => {
            const { data } = await api.put(`/teams/${teamId}`, teamData);
            return data as TeamResponse;
        }
    });
};

// Delete team
export const useDeleteTeam = (teamId: string) => {
    const api = useApi();
    return useMutation({
        mutationFn: async () => {
            const { data } = await api.delete(`/teams/${teamId}`);
            return data;
        }
    });
};

// Get team members
export const useTeamMembers = (teamId: string) => {
    const api = useApi();
    return useQuery({
        queryKey: ["teams", teamId, "members"],
        queryFn: async () => {
            const { data } = await api.get(`/teams/${teamId}/members`);
            return data as TeamMember[];
        }
    });
};

// Add team member
export const useAddTeamMember = (teamId: string) => {
    const api = useApi();
    return useMutation({
        mutationFn: async (data: {
            email?: string;
            mobile?: string;
            countryCode?: string;
            isAdmin: boolean;
        }) => {
            const { data: response } = await api.post(`/teams/${teamId}/members`, data);
            return response as TeamMember;
        }
    });
};

// Remove team member
export const useRemoveTeamMember = (teamId: string) => {
    const api = useApi();
    return useMutation({
        mutationFn: async (userId: string) => {
            const { data } = await api.delete(`/teams/${teamId}/members/${userId}`);
            return data as boolean;
        }
    });
};

// Update member status
export const useUpdateMemberStatus = (teamId: string) => {
    const api = useApi();
    return useMutation({
        mutationFn: async ({ userId, status }: { userId: string; status: string }) => {
            const { data } = await api.put(`/teams/${teamId}/members/${userId}/status`, { status });
            return data as TeamMember;
        }
    });
};

// Create team access code
export const useCreateTeamAccessCode = (teamId: string) => {
    const api = useApi();
    return useMutation({
        mutationFn: async (codeData: { maxUses?: number; expiresAt?: string }) => {
            const { data } = await api.post(`/teams/${teamId}/access-codes`, codeData);
            return data as TeamAccessCode;
        }
    });
};

// Join team with code
export const useJoinTeam = () => {
    const api = useApi();
    return useMutation({
        mutationFn: async (accessCode: string) => {
            const { data } = await api.post('/teams/join', { accessCode });
            return data as { teamId: string; membership: TeamMember };
        }
    });
};

// Get team access codes
export const useTeamAccessCodes = (teamId: string) => {
    const api = useApi();
    return useQuery({
        queryKey: ['teams', teamId, 'access-codes'],
        queryFn: async () => {
            const { data } = await api.get(`/teams/${teamId}/access-codes`);
            return data as TeamAccessCode[];
        },
        enabled: !!teamId
    });
};

// Toggle access code status
export const useToggleAccessCode = (teamId: string) => {
    const api = useApi();
    return useMutation({
        mutationFn: async (code: string) => {
            const { data } = await api.put(`/teams/${teamId}/access-codes/${code}/toggle`);
            return data as TeamAccessCode;
        }
    });
};
