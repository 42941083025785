import { useEffect, useState } from 'react';
import { useCreateSubscriptionOrder, useSubscriptionPlans, useVerifySubscriptionPayment, useUserSubscriptions } from '@/services/subscriptionService';
import { useAuth } from '@/contexts/AuthContext';
import { toast } from 'sonner';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTeams } from '@/services/teamService';
import { SubscriptionPlan } from '@/types/subscription';

export function useSubscription() {
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan | null>(null);
    const [seats, setSeats] = useState('');
    const [searchParams] = useSearchParams();
    const teamId = searchParams.get('teamId');
    const { data: plans, isLoading: isLoadingPlans } = useSubscriptionPlans();
    const { data: userSubscriptions, isLoading: isLoadingSubscriptions } = useUserSubscriptions();
    const { data: teams } = useTeams();
    const createOrder = useCreateSubscriptionOrder();
    const verifyPayment = useVerifySubscriptionPayment();
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const setSelectedPlanAndSeats = (plan: SubscriptionPlan | null) => {
        setSelectedPlan(plan);
        if (!plan) {
            setSeats('');
        } else if (!plan.isTeamPlan) {
            setSeats('1');
        } else {
            setSeats(plan.minSeats?.toString() || '1');
        }
    };

    const resetState = () => {
        setSelectedPlan(null);
        setSeats('');
        setIsProcessingPayment(false);
    };

    const getDiscount = (numSeats: number, discounts: Record<string, number>) => {
        let maxDiscount = 0;
        Object.entries(discounts).forEach(([seats, discount]) => {
            if (numSeats >= parseInt(seats) && discount > maxDiscount) {
                maxDiscount = discount;
            }
        });
        return maxDiscount;
    };

    const calculateTotalPrice = (plan: SubscriptionPlan, numSeats: number) => {
        if (!plan.isTeamPlan) return plan.basePrice;
        
        const baseTotal = plan.basePrice * numSeats;
        if (!plan.bulkDiscounts || numSeats === 0) return baseTotal;

        // Calculate discount if applicable
        const discount = getDiscount(numSeats, plan.bulkDiscounts);
        return Math.round(baseTotal * (1 - discount / 100));
    };

    const handlePurchase = async () => {
        if (!selectedPlan) return;
        if (selectedPlan.isTeamPlan && !teamId) {
            toast.error("Team ID is required for team subscriptions");
            return;
        }

        const numSeats = parseInt(seats);
        
        // Validate seats before proceeding
        if (selectedPlan.isTeamPlan && selectedPlan.minSeats) {
            if (isNaN(numSeats) || numSeats < selectedPlan.minSeats) {
                toast.error(`Minimum ${selectedPlan.minSeats} seats required for this plan`);
                setSeats(selectedPlan.minSeats.toString());
                return;
            }
        }

        const amount = calculateTotalPrice(selectedPlan, numSeats);

        try {
            setIsProcessingPayment(true);

            const orderData = await createOrder.mutateAsync({
                planId: selectedPlan._id,
                type: selectedPlan.isTeamPlan ? "TEAM" : "INDIVIDUAL",
                teamId: selectedPlan.isTeamPlan ? teamId ?? undefined : undefined,
                seats: numSeats
            });

            // If subscription is already active (free plan), redirect to dashboard
            if (orderData.subscription.status === 'ACTIVE') {
                toast.success('Subscription activated successfully!');
                if (selectedPlan.isTeamPlan) {
                    navigate(`/teams/${teamId}`);
                } else {
                    navigate('/dashboard');
                }
                return;
            }

            const options = {
                key: "rzp_test_4MtJ3MvwsXU49D", // Replace with your Razorpay key
                amount: orderData.paymentOrder.amount,
                currency: orderData.paymentOrder.currency,
                name: 'Vedita AI',
                description: `Purchase ${selectedPlan.name} Subscription`,
                order_id: orderData.paymentOrder.id,
                handler: async (response: {
                    razorpay_payment_id: string;
                    razorpay_order_id: string;
                    razorpay_signature: string;
                }) => {
                    try {
                        const verifyData = await verifyPayment.mutateAsync({
                            subscriptionId: orderData.subscription._id,
                            paymentData: {
                                razorpay_payment_id: response.razorpay_payment_id,
                                razorpay_order_id: response.razorpay_order_id,
                                razorpay_signature: response.razorpay_signature
                            }
                        });
                        
                        if (verifyData.success) {
                            toast.success("Subscription purchased successfully!");
                            // Navigate to team details if it's a team subscription
                            if (selectedPlan.isTeamPlan) {
                                navigate(`/teams/${teamId}`);
                            } else {
                                navigate('/dashboard');
                            }
                        } else {
                            toast.error("Payment verification failed. Please contact support.");
                        }
                    } catch (error) {
                        console.error('Payment verification failed:', error);
                        toast.error('Payment verification failed. Please contact support.');
                    } finally {
                        resetState();
                    }
                },
                modal: {
                    ondismiss: () => {
                        resetState();
                    }
                },
                prefill: {
                    name: user?.name,
                    email: user?.email,
                    contact: user?.mobile
                },
                theme: {
                    color: '#0C4A6E'
                }
            };

            const razorpay = new (window as any).Razorpay(options);
            razorpay.open();
        } catch (error) {
            console.error('Order creation failed:', error);
            toast.error('Failed to create order');
            resetState();
        }
    };

    return {
        plans: plans || [],
        teams,
        userSubscriptions,
        isLoading: isLoadingPlans || isLoadingSubscriptions,
        isProcessingPayment,
        selectedPlan,
        setSelectedPlan: setSelectedPlanAndSeats,
        seats,
        setSeats,
        handlePurchase,
        resetState
    };
}
