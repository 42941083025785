import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Plus, Copy, Calendar } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { format } from "date-fns";
import { useState } from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { useAuth } from "@/contexts/AuthContext";
import { TeamAccessCode,TeamResponse } from "@/types/team";

interface AccessCodesTabProps {
    accessCodes: TeamAccessCode[];
    isCreatingCode: boolean;
    createAccessCode: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
    toggleAccessCode: (code: string) => Promise<void>;
    copyToClipboard: (code: string) => void;
    team: TeamResponse;
}

export function AccessCodesTab({ 
    accessCodes, 
    isCreatingCode, 
    createAccessCode, 
    toggleAccessCode, 
    copyToClipboard,
    team 
}: AccessCodesTabProps) {
    const [createCodeOpen, setCreateCodeOpen] = useState(false);
    const { user } = useAuth();
    
    const isOwner = user?.id === team.ownerId;

    // If not owner, don't show anything
    if (!isOwner) {
        return (
            <Card>
                <CardHeader>
                    <CardTitle>Access Codes</CardTitle>
                    <CardDescription>Only team owners can manage access codes</CardDescription>
                </CardHeader>
            </Card>
        );
    }

    const handleCreateCode = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await createAccessCode(e);
        setCreateCodeOpen(false);
    };

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
                <div className="space-y-1">
                    <CardTitle>Access Codes</CardTitle>
                    <CardDescription>Create and manage team invitation codes</CardDescription>
                </div>
                <Button variant="orange" onClick={() => setCreateCodeOpen(true)}>
                    <Plus className="mr-2 h-4 w-4" />
                    Create Code
                </Button>
            </CardHeader>
            <CardContent className="p-0">
                <div className="divide-y">
                    {accessCodes?.map((code) => (
                        <div
                            key={code.code}
                            className="flex items-center justify-between p-4 hover:bg-accent/50 transition-colors"
                        >
                            <div className="flex items-center space-x-4">
                                <div className="space-y-1">
                                    <div className="flex items-center space-x-2">
                                        <code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                                            {code.code}
                                        </code>
                                        <Badge variant={code.isActive ? "default" : "secondary"}>
                                            {code.isActive ? "Active" : "Inactive"}
                                        </Badge>
                                    </div>
                                    <div className="flex items-center text-sm text-muted-foreground space-x-4">
                                       
                                        {code.expiresAt && (
                                            <>
                                                <span className="flex items-center">
                                                    <Calendar className="mr-1 h-3 w-3 text-danger" />
                                                    <span className="font-semibold text-danger">
                                                        {format(new Date(code.expiresAt), "PPp")}
                                                    </span>
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center space-x-2">
                                <Button
                                    variant="ghost"
                                    size="icon"
                                    onClick={() => copyToClipboard(code.code)}
                                >
                                    <Copy className="h-4 w-4" />
                                </Button>
                                <Button
                                    variant={code.isActive ? "destructive" : "orange"}
                                    size="sm"
                                    onClick={() => toggleAccessCode(code.code)}
                                >
                                    {code.isActive ? "Disable" : "Enable"}
                                </Button>
                            </div>
                        </div>
                    ))}
                    {!accessCodes?.length && (
                        <div className="p-4 text-center text-sm text-muted-foreground">
                            No access codes yet. Create one to invite team members!
                        </div>
                    )}
                </div>
            </CardContent>

            <Dialog open={createCodeOpen} onOpenChange={setCreateCodeOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Create Access Code</DialogTitle>
                        <DialogDescription>
                            Create a new access code for inviting team members.
                        </DialogDescription>
                    </DialogHeader>
                    <form onSubmit={handleCreateCode} className="space-y-6">
                        <div className="space-y-4">
                            <div className="grid gap-1.5">
                                <Label htmlFor="maxUses">Maximum Uses</Label>
                                <Input
                                    id="maxUses"
                                    name="maxUses"
                                    type="number"
                                    min="1"
                                    placeholder="Leave empty for unlimited uses"
                                />
                                <p className="text-sm text-muted-foreground">
                                    Set a limit on how many times this code can be used
                                </p>
                            </div>
                            <div className="grid gap-1.5">
                                <Label htmlFor="expiresAt">Expiry Date</Label>
                                <Input
                                    id="expiresAt"
                                    name="expiresAt"
                                    type="datetime-local"
                                />
                                <p className="text-sm text-muted-foreground">
                                    Optional: Set when this code should expire
                                </p>
                            </div>
                        </div>
                        <DialogFooter>
                            <Button type="button" variant="outline" onClick={() => setCreateCodeOpen(false)}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="orange" disabled={isCreatingCode}>
                                Create Code
                            </Button>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>
        </Card>
    );
}
