import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <div className="max-w-4xl mx-auto">
                <Link to="/" className="inline-flex items-center text-sm text-muted-foreground hover:text-primary mb-4">
                    <ArrowLeft className="w-4 h-4 mr-2" />
                    Back to Home
                </Link>
                <Card className="w-full h-full">
                    <CardHeader>
                        <CardTitle className="text-3xl font-bold mb-6">Privacy Policy</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <ScrollArea className="h-full w-full pr-4">
                            <div className="space-y-6">
                                <section>
                                    
                                    <p className="text-sm text-muted-foreground">
                                        This policy explains how Desi LLM ("Desi LLM PVT. LTD.", and its affiliates and subsidiaries, also referred to below as "VEDITA.AI", "we", "us" or "our") handles your personal data - from collecting it to protecting it. We value your privacy and want you to understand how your information is treated. This includes what data we gather, why we need it, what we do with it, and how you can control it.
                                    </p>
                                </section>

                                <section>
                                    <h3 className="text-lg font-semibold mb-2">Last Updated</h3>
                                    <p className="text-sm text-muted-foreground">
                                        This Privacy Notice was last updated and is effective on 01 June 2024
                                    </p>
                                </section>

                                <section>
                                    <h3 className="text-lg font-semibold mb-2">Scope</h3>
                                    <p className="text-sm text-muted-foreground">
                                        This Privacy Policy is applicable to our Services and any other interactions you might engage in with us, including visiting our websites, making customer service inquiries, attending events, and more. Your utilization of our Services or any form of interaction implies your consent to the gathering and utilization of your personal information as outlined in this Privacy Policy by VEDITA.AI. Personal information encompasses any data or information associated with you that can be utilized to identify you, either directly or indirectly, or to establish contact with you.
                                    </p>
                                </section>

                                <section>
                                    <h3 className="text-lg font-semibold mb-2">Compliance</h3>
                                    <p className="text-sm text-muted-foreground">
                                        We are committed to protecting the privacy of our clients, customers, and website visitors. As such, we have implemented policies and procedures to ensure that we comply with the GDPR and other applicable data protection laws and regulations.
                                    </p>
                                </section>

                                <section>
                                    <h3 className="text-lg font-semibold mb-2">Data Protection</h3>
                                    <p className="text-sm text-muted-foreground">
                                        We understand that your personal data is important to you, and we take our responsibility to protect it seriously. We are committed to being transparent about our data processing practices, and we strive to provide clear and concise information to help you understand how we use your personal data.
                                    </p>
                                </section>

                                <section>
                                    <h3 className="text-lg font-semibold mb-2">Best Practices</h3>
                                    <p className="text-sm text-muted-foreground">
                                        In addition to complying with the GDPR, we also adhere to best practices in data protection and privacy. We have implemented a range of technical and organizational measures to ensure the security and confidentiality of personal data. Our employees are trained on data protection and privacy best practices, and we conduct regular audits to ensure that our policies and procedures remain up-to-date and effective.
                                    </p>
                                </section>

                                <section>
                                    <h3 className="text-lg font-semibold mb-2">Third-Party Services</h3>
                                    <p className="text-sm text-muted-foreground">
                                        Within our Services, you may encounter links to external websites, products, or services that are not under our ownership or control. While utilizing our Services, you have the option to share your data and associated personal information with a third-party service of your choice. The handling of your information by these third parties is regulated by their individual privacy policies and terms. We advise you to thoroughly examine their privacy policies and terms since we assume no responsibility for the practices of such third-party services.
                                    </p>
                                </section>

                                <section>
                                    <h3 className="text-lg font-semibold mb-2">Continuous Improvement</h3>
                                    <p className="text-sm text-muted-foreground">
                                        We acknowledge that safeguarding data is an evolving effort, and we are dedicated to consistently enhancing our procedures to uphold the utmost standards of data protection and privacy. Should you have any inquiries or reservations regarding our privacy practices, please feel free to get in touch with us.
                                    </p>
                                </section>
                                <section>
                                    <h3 className="text-lg font-semibold mb-2">Acknowledgment</h3>
                                    <p className="text-sm text-muted-foreground">
                                        It is essential to carefully read this Privacy Policy. By utilizing our services, visiting our website, or furnishing us with your personal information, you acknowledge that you have perused, comprehended, and consent to the terms outlined in this Privacy Policy.
                                    </p>
                                    <p className="text-sm text-muted-foreground font-semibold mt-2">
                                        BY ACCESSING OR USING THE SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTOOD THIS PRIVACY POLICY AND CONSENT TO THE COLLECTION, USE, AND SHARING OF YOUR PERSONAL DATA AS DESCRIBED HEREIN. IF YOU DO NOT AGREE WITH THIS POLICY, PLEASE DO NOT USE OUR SERVICES.
                                    </p>
                                </section>

                                <section>
                                    <h3 className="text-lg font-semibold mb-2">1. Introduction</h3>
                                    <ul className="list-disc pl-6 space-y-2">
                                        <li className="text-sm text-muted-foreground">
                                            <strong>Overview of the policy and its purpose</strong>
                                        </li>
                                    </ul>
                                    <p className="text-sm text-muted-foreground mt-2">
                                        We hold the belief that privacy is a fundamental right, and our commitment is unwavering when it comes to safeguarding the privacy and security of our users' personal information. This Privacy Policy articulates our dedication to privacy, detailing the kinds of information we gather, how we utilize that information, and the measures we implement to ensure the protection of user privacy. BY USING OUR PLATFORM, YOU CONSENT TO THE PRACTICES OUTLINED IN THIS PRIVACY POLICY.
                                    </p>
                                    <ul className="list-disc pl-6 space-y-2 mt-2">
                                        <li className="text-sm text-muted-foreground">
                                            <strong>Scope of the Policy</strong>
                                        </li>
                                    </ul>
                                    <p className="text-sm text-muted-foreground mt-2">
                                        This privacy policy applies to all personal data processed by VEDITA.AI in connection with the Services, including personal data collected through our website, mobile applications, and other digital or offline means. It also applies to personal data collected from our users, partners, vendors, and other third parties.
                                    </p>
                                </section>
                                <section>
                                    <h3 className="text-lg font-semibold mb-2">Definitions</h3>
                                    <p className="text-sm text-muted-foreground">
                                        Throughout this privacy policy, certain terms may have specific meanings, as defined by the GDPR. These include:
                                    </p>
                                    <ul className="list-disc pl-6 space-y-2 mt-2">
                                        <li className="text-sm text-muted-foreground">
                                            <strong>Consent:</strong> Any freely given, specific, informed, and unambiguous indication of the data subject's wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.
                                        </li>
                                        <li className="text-sm text-muted-foreground">
                                            <strong>Controller:</strong> The natural or legal person, public authority, agency, or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data.
                                        </li>
                                        <li className="text-sm text-muted-foreground">
                                            <strong>Data Breach:</strong> A breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, personal data transmitted, stored or otherwise processed.
                                        </li>
                                        <li className="text-sm text-muted-foreground">
                                            <strong>Data Portability:</strong> The right of a data subject to receive the personal data concerning him or her, which he or she has provided to a controller, in a structured, commonly used and machine-readable format and have the right to transmit those data to another controller without hindrance from the controller to which the personal data have been provided.
                                        </li>
                                        <li className="text-sm text-muted-foreground">
                                            <strong>Data Subject:</strong> The individual to whom the personal data relates.
                                        </li>
                                        <li className="text-sm text-muted-foreground">
                                            <strong>Personal data:</strong> Any information relating to an identified or identifiable natural person (data subject).
                                        </li>
                                        <li className="text-sm text-muted-foreground">
                                            <strong>Processing:</strong> Any operation or set of operations performed on personal data, such as collection, recording, organization, structuring, storage, adaptation, or disclosure by transmission, dissemination or otherwise making available, erasure, or destruction.
                                        </li>
                                        <li className="text-sm text-muted-foreground">
                                            <strong>Processor:</strong> A natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.
                                        </li>
                                        <li className="text-sm text-muted-foreground">
                                            <strong>Special categories of personal data:</strong> Sensitive data, such as data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data, biometric data, data concerning health or data concerning a natural person's sex life or sexual orientation.
                                        </li>
                                        <li className="text-sm text-muted-foreground">
                                            <strong>Supervisory authority:</strong> An independent public authority responsible for monitoring the application of the GDPR, including investigating complaints and conducting audits and inspections.
                                        </li>
                                    </ul>
                                </section>

                                <section>
                                    <h3 className="text-lg font-semibold mb-2">2. Types of Personal Data Collected</h3>
                                    <h4 className="text-md font-semibold mb-2">Definition of personal data</h4>
                                    <p className="text-sm text-muted-foreground">
                                        Personal data refers to information about an identified or identifiable person, encompassing a wide range of identifiers such as name, location, or genetic details. Under the GDPR, this data is considered a fundamental right, and those handling it must ensure lawful, fair, and transparent processing. They should collect data for specific, legitimate purposes, ensure its accuracy, and protect it adequately. The GDPR emphasizes the need for maintaining data security, confidentiality, and restricting unnecessary transfers.
                                    </p>
                                </section>

                                <section>
                                    <h4 className="text-md font-semibold mb-2">Types of personal data collected</h4>
                                    <p className="text-sm text-muted-foreground">
                                        We gather and handle various types of personal data when you utilize our Services, and the details and purposes are outlined below:
                                    </p>
                                    <ul className="list-disc pl-6 space-y-2 mt-2">
                                        <li className="text-sm text-muted-foreground">
                                            <strong>Information provided directly:</strong> When creating an account, you may furnish personal data like your name, email, phone number, and date of birth. This data is used for managing your account, verifying identity, communication, and personalizing your experience.
                                        </li>
                                        <li className="text-sm text-muted-foreground">
                                            <strong>Information collected automatically:</strong> While using our Services, certain information is collected automatically, including IP address, device type, browser, and technical details. This data aids in enhancing service performance, analyzing trends, and addressing technical issues.
                                        </li>
                                        <li className="text-sm text-muted-foreground">
                                            <strong>Usage information:</strong> Data on how you interact with our Services, such as feature usage, visited pages, viewed content, and actions taken, is gathered. This information is used to personalize your experience, understand preferences, and improve Services.
                                        </li>
                                        <li className="text-sm text-muted-foreground">
                                            <strong>Location data:</strong> With your permission, we may collect and process your location information to provide location-based services, including personalized content and advertising.
                                        </li>
                                        <li className="text-sm text-muted-foreground">
                                            <strong>Special categories of personal data:</strong> In certain instances, we may collect sensitive data, like health information, if provided voluntarily or necessary for specific Services. Processing such data requires explicit consent or compliance with applicable law.
                                        </li>
                                    </ul>
                                    <p className="text-sm text-muted-foreground font-semibold mt-4">
                                        WE WILL ONLY USE YOUR PERSONAL DATA FOR THE PURPOSES FOR WHICH WE COLLECTED IT, UNLESS WE REASONABLY CONSIDER THAT WE NEED TO USE IT FOR ANOTHER REASON THAT IS COMPATIBLE WITH THE ORIGINAL PURPOSE. IF WE NEED TO USE YOUR PERSONAL DATA FOR AN UNRELATED PURPOSE, WE WILL NOTIFY YOU AND EXPLAIN THE LEGAL BASIS WHICH ALLOWS US TO DO SO.
                                    </p>
                                </section>

                                <section>
                                    <h4 className="text-md font-semibold mb-2">Information collected automatically</h4>
                                    <p className="text-sm text-muted-foreground">
                                        Information collected automatically refers to data acquired by a website or application without explicit user input, utilizing methods like cookies, log files, web beacons, and tracking technologies. This data enhances the user experience, delivers pertinent content, and facilitates trend analysis.
                                    </p>
                                    <p className="text-sm text-muted-foreground mt-2">
                                        Common examples include the user's IP address, browser type, operating system, device type, language preference, and referral source. Log files, storing user activity details, aid in trend analysis and performance monitoring. Cookies, small text files, record user preferences and activities, personalizing experiences and analyzing trends.
                                    </p>
                                    <p className="text-sm text-muted-foreground mt-2">
                                        Web beacons, small embedded images, track user activity, contributing to trend analysis. This information, collected non-intrusively, aims to enhance user experience and provide relevant content. It is crucial to adhere to data protection laws like GDPR when collecting and utilizing this information.
                                    </p>
                                </section>
                                <section>
                                    <h3 className="text-lg font-semibold mb-2">3. How Data is Collected</h3>
                                    <p className="text-sm text-muted-foreground">
                                        Personal data is collected through direct user interactions, such as signing up for services, and indirectly via partners and third-party sources like social media platforms. The information gathered includes details like name, email, phone number, and job title, relevant to the services provided. The data collection is purpose-driven, ensuring only necessary information is obtained, and measures are in place to maintain accuracy and compliance with data protection laws.
                                    </p>
                                    <p className="text-sm text-muted-foreground font-semibold mt-4">
                                        WE ONLY COLLECT DATA THAT IS NECESSARY FOR THE PURPOSES FOR WHICH IT IS PROCESSED, AND WE TAKE APPROPRIATE MEASURES TO ENSURE THAT THE DATA WE COLLECT IS ACCURATE, UP-TO-DATE, AND RELEVANT TO THE SERVICE WE PROVIDE. WE ALSO ENSURE THAT ALL DATA IS COLLECTED AND PROCESSED IN COMPLIANCE WITH APPLICABLE DATA PROTECTION LAWS.
                                    </p>
                                </section>

                                <section>
                                    <h3 className="text-lg font-semibold mb-2">4. Legal Basis for Processing Data</h3>
                                    <ul className="list-disc pl-6 space-y-4">
                                        <li>
                                            <h4 className="text-md font-semibold">Consent</h4>
                                            <p className="text-sm text-muted-foreground">
                                                Individuals provide explicit and informed consent for the processing of their personal data, allowing VEDITA.AI to use their data for specific purposes. Consent can be withdrawn at any time, particularly for activities like marketing.
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="text-md font-semibold">Contractual Necessity</h4>
                                            <p className="text-sm text-muted-foreground">
                                                Processing personal data may be necessary for fulfilling a contract or taking pre-contractual steps as requested by the individual. For example, processing data is essential to provide online services.
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="text-md font-semibold">Legal Obligation</h4>
                                            <p className="text-sm text-muted-foreground">
                                                VEDITA.AI may process personal data to fulfil legal obligations, such as retaining specific data for a mandated period. This processing is limited to what is necessary to comply with legal requirements.
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="text-md font-semibold">Vital Interests</h4>
                                            <p className="text-sm text-muted-foreground">
                                                Processing may occur when it's crucial to protect the vital interests of the data subject or others, such as providing medical assistance during a medical emergency.
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="text-md font-semibold">Legitimate Interests</h4>
                                            <p className="text-sm text-muted-foreground">
                                                Processing is permitted when it's necessary for the legitimate interests of VEDITA.AI or a third party, unless overridden by the rights of the data subject. A balancing of interests is required, for example, in fraud prevention.
                                            </p>
                                        </li>
                                    </ul>
                                    <p className="text-sm text-muted-foreground font-semibold mt-4">
                                        VEDITA.AI COMMITS TO PROCESSING PERSONAL DATA SOLELY FOR THE PURPOSES IT WAS COLLECTED, REFRAINING FROM USING THE DATA FOR ANY OTHER PURPOSE WITHOUT ADDITIONAL CONSENT OR UNLESS PERMITTED BY LAW. IF A NEW PURPOSE ARISES THAT ISN'T COMPATIBLE WITH THE ORIGINAL ONE, VEDITA.AI INFORMS DATA SUBJECTS AND SEEKS THEIR CONSENT IF NECESSARY.
                                    </p>
                                </section>


                                <section>
                                    <h3 className="text-lg font-semibold mb-2">5. Use of Data (Continued)</h3>
                                    <ul className="list-disc pl-6 space-y-4">
                                        <li>
                                            <h4 className="text-md font-semibold">User Communication</h4>
                                            <p className="text-sm text-muted-foreground">
                                                Personal data is processed to engage with users, addressing their inquiries, sending newsletters or promotional messages, and providing updates on products and services.
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="text-md font-semibold">Personalization</h4>
                                            <p className="text-sm text-muted-foreground">
                                                Processing personal data is employed to tailor products and services to individual user interests, preferences, and usage patterns. This involves analysing user behaviour and interactions for a personalized experience.
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="text-md font-semibold">Legal Compliance</h4>
                                            <p className="text-sm text-muted-foreground">
                                                Personal data is processed to fulfil legal obligations, responding to legal requests, preventing fraud or illegal activities, and ensuring compliance with terms of service.
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="text-md font-semibold">Product and Service Improvement</h4>
                                            <p className="text-sm text-muted-foreground">
                                                Personal data is utilized for research and development, testing new features, analysing usage patterns, and enhancing the overall quality and performance of products and services.
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="text-md font-semibold">Sharing and International Data Transfers</h4>
                                            <p className="text-sm text-muted-foreground">
                                                Personal data may be shared with third-party service providers for service provision and with business partners for marketing, subject to user consent where required. International data transfers, even to countries with lower data protection levels, will have appropriate safeguards in place, such as standard contractual clauses or other legal mechanisms. Users have the right to object to direct marketing and can exercise other data subject rights under GDPR. For questions or concerns about personal data use, users can refer to Section 10 for contact information.
                                            </p>
                                        </li>
                                    </ul>
                                </section>

                                <section>
                                    <h3 className="text-lg font-semibold mb-2">6. Consent</h3>
                                    <h4 className="text-md font-semibold mb-2">Requirements</h4>
                                    <p className="text-sm text-muted-foreground">
                                        Consent is one of the legal bases for processing personal data under this Policy. In order for consent to be valid, it must meet the following requirements:
                                    </p>
                                    <ul className="list-disc pl-6 space-y-2 mt-2">
                                        <li className="text-sm text-muted-foreground">Freely given: Consent must be given voluntarily and without coercion.</li>
                                        <li className="text-sm text-muted-foreground">Specific: Consent must be specific to the purpose for which it is given.</li>
                                        <li className="text-sm text-muted-foreground">Informed: Users must be provided with clear and understandable information about the processing of their personal data.</li>
                                        <li className="text-sm text-muted-foreground">Unambiguous: Consent must be given through a clear affirmative action.</li>
                                        <li className="text-sm text-muted-foreground">Revocable: Users must have the right to withdraw their consent at any time.</li>
                                    </ul>

                                    <h4 className="text-md font-semibold mb-2 mt-4">How consent will be obtained</h4>
                                    <p className="text-sm text-muted-foreground">
                                        We will obtain consent from users in a clear and transparent manner, and will ensure that the consent meets the above requirements. We will use the following methods to obtain consent:
                                    </p>
                                    <ul className="list-disc pl-6 space-y-2 mt-2">
                                        <li className="text-sm text-muted-foreground">Opt-in mechanisms: We will use clear and conspicuous opt-in mechanisms, such as checkboxes or buttons, to obtain users' consent.</li>
                                        <li className="text-sm text-muted-foreground">Granular consent: We will obtain separate and specific consent for each distinct purpose of processing, where appropriate.</li>
                                        <li className="text-sm text-muted-foreground">Records of consent: We will keep records of users' consent, including the purpose of processing, the method of obtaining consent, and the date and time of consent.</li>
                                        <li className="text-sm text-muted-foreground">Withdrawal of consent: We will provide users with an easy and accessible way to withdraw their consent, and will honour all requests to withdraw consent in a timely manner.</li>
                                    </ul>
                                </section>

                                <section>
                                    <h3 className="text-lg font-semibold mb-2">7. Rights of Data Subjects</h3>
                                    <ul className="list-disc pl-6 space-y-4">
                                        <li>
                                            <h4 className="text-md font-semibold">Right to Access</h4>
                                            <p className="text-sm text-muted-foreground">
                                                Allows individuals to confirm if their personal data is being processed and obtain details about the categories, purposes, recipients, and retention periods. They can request a copy in a commonly used electronic format.
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="text-md font-semibold">Right to Rectification</h4>
                                            <p className="text-sm text-muted-foreground">
                                                Permits individuals to request correction of inaccurate or incomplete personal data, considering the purposes for which it was processed.
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="text-md font-semibold">Right to Erasure</h4>
                                            <p className="text-sm text-muted-foreground">
                                                Also known as the "right to be forgotten," allows individuals to request deletion of personal data in specific circumstances, though legal or other reasons may necessitate data retention.
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="text-md font-semibold">Right to Restrict Processing</h4>
                                            <p className="text-sm text-muted-foreground">
                                                Enables individuals to limit the processing of their personal data in certain situations. While data storage continues, processing ceases without consent unless legally required or to protect another person's rights.
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="text-md font-semibold">Right to Data Portability</h4>
                                            <p className="text-sm text-muted-foreground">
                                                Grants individuals the right to receive a structured, commonly used, and machine-readable copy of their personal data for transfer to another data controller, applicable to data provided with consent or based on a contract.
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="text-md font-semibold">Right to Object</h4>
                                            <p className="text-sm text-muted-foreground">
                                                Allows individuals to object to personal data processing, particularly for direct marketing or research purposes. Processing stops unless compelling legitimate grounds override the individual's interests, rights, and freedoms, or if needed for legal claims.
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="text-md font-semibold">Right to Withdraw Consent</h4>
                                            <p className="text-sm text-muted-foreground">
                                                Permits individuals to withdraw consent for processing personal data based on their consent. Withdrawal does not affect prior lawful processing. To exercise any of these rights, please contact us using the details provided in the "Contact Information" section below. We may need to verify your identity before we can respond to your request. We will respond to your request as soon as possible.
                                            </p>
                                        </li>
                                    </ul>
                                </section>

                                <section>
                                    <h3 className="text-lg font-semibold mb-2">8. Data Retention</h3>
                                    <p className="text-sm text-muted-foreground">
                                        We retain personal data only for as long as necessary to fulfil the purposes for which it was collected, unless a longer retention period is required by law or our legitimate interests. The retention periods for different types of personal data depend on various factors, including the nature of the data and the purposes for which it was collected.
                                    </p>
                                    <h4 className="text-md font-semibold mb-2 mt-4">Retention Periods</h4>
                                    <p className="text-sm text-muted-foreground">
                                        Different types of personal data have varied retention periods determined by factors like data nature and collection purposes:
                                    </p>
                                    <ul className="list-disc pl-6 space-y-2 mt-2">
                                        <li className="text-sm text-muted-foreground">Contractual data: Retained for the contract duration and a required post-contract period.</li>
                                        <li className="text-sm text-muted-foreground">Marketing data: Retained until consent withdrawal or objection exercise.</li>
                                        <li className="text-sm text-muted-foreground">Legal compliance data: Retained for the period mandated by applicable law.</li>
                                    </ul>
                                    <h4 className="text-md font-semibold mb-2 mt-4">Criteria for Determining Retention Periods</h4>
                                    <p className="text-sm text-muted-foreground">
                                        Retention periods are decided based on:
                                    </p>
                                    <ul className="list-disc pl-6 space-y-2 mt-2">
                                        <li className="text-sm text-muted-foreground">Purpose of data collection.</li>
                                        <li className="text-sm text-muted-foreground">Nature of personal data.</li>
                                        <li className="text-sm text-muted-foreground">Legal or regulatory obligations.</li>
                                        <li className="text-sm text-muted-foreground">Our legitimate interests in retaining the data.</li>
                                    </ul>
                                    <h4 className="text-md font-semibold mb-2 mt-4">Exceptions to Retention Periods</h4>
                                    <p className="text-sm text-muted-foreground">
                                        Personal data may be retained longer than specified:
                                    </p>
                                    <ul className="list-disc pl-6 space-y-2 mt-2">
                                        <li className="text-sm text-muted-foreground">To comply with legal obligations.</li>
                                        <li className="text-sm text-muted-foreground">To protect legitimate interests, e.g., resolving disputes, preventing fraud, or enforcing agreements.</li>
                                    </ul>
                                    <p className="text-sm text-muted-foreground mt-2">
                                        If we no longer need personal data for any purpose and are not required by law to retain it, we will securely delete or destroy it in accordance with our data retention and disposal policies.
                                    </p>
                                </section>
                                <section>
                                    <h3 className="text-lg font-semibold mb-2">9. Changes to the Policy</h3>
                                    <p className="text-sm text-muted-foreground">
                                        We reserve the right to modify this privacy policy at any time. If we make any material changes to the policy, we will provide notice to data subjects through our website, email, or other appropriate means. We will also indicate the date of the most recent update to the policy.
                                    </p>
                                </section>

                                <section>
                                    <h3 className="text-lg font-semibold mb-2">10. Contact Information</h3>
                                    <h4 className="text-md font-semibold mb-2">Data Controller Information</h4>
                                    <p className="text-sm text-muted-foreground">
                                        As the data controller, VEDITA.AI is responsible for collecting, processing, and storing personal data in compliance with this Policy.
                                    </p>

                                    <h4 className="text-md font-semibold mb-2 mt-4">Contact Information for Data Subject Requests</h4>
                                    <p className="text-sm text-muted-foreground">
                                        If you would like to make a request to access, rectify, erase, restrict processing, or port your personal data, you can contact us using the following methods:
                                    </p>
                                    <div className="mt-2 space-y-1">
                                        <p className="text-sm text-muted-foreground"><strong>Desi LLM PVT. LTD.</strong></p>
                                        <p className="text-sm text-muted-foreground">CTS NO. 93, Krishnai Building, Raghuvendra Society Vinayak Nagar, Belagavi, Karnataka 591108</p>
                                        <p className="text-sm text-muted-foreground">support@vedita.ai</p>
                                        <p className="text-sm text-muted-foreground">Mobile: +91 7975827078</p>
                                        <p className="text-sm text-muted-foreground">https://www.vedita.ai</p>
                                    </div>

                                    <h4 className="text-md font-semibold mb-2 mt-4">Verification of identity:</h4>
                                    <p className="text-sm text-muted-foreground">
                                        To protect the security and privacy of your personal data, we will ask you to provide proof of your identity before we can process your request. We will respond to your request within fifteen days of verifying your identity.
                                    </p>
                                </section>

                                <section>
                                    <h3 className="text-lg font-semibold mb-2">11. Complaints</h3>
                                    <h4 className="text-md font-semibold mb-2">Complaints Procedure</h4>
                                    <p className="text-sm text-muted-foreground">
                                        We take all complaints about our handling of personal data seriously and are committed to addressing them promptly and effectively. Data subjects can submit a complaint about our data handling practices by contacting us using the information provided in the Contact Information section of this Privacy Policy. We will acknowledge receipt of the complaint and provide the data subject with a reference number for tracking purposes.
                                    </p>

                                    <h4 className="text-md font-semibold mb-2 mt-4">Investigation and Resolution</h4>
                                    <p className="text-sm text-muted-foreground">
                                        We will investigate the complaint promptly and thoroughly, taking into account all relevant circumstances and any applicable legal requirements. We may request additional information or documentation from the data subject or any third parties involved in order to assist with the investigation. We will notify the data subject of the outcome of the investigation and any remedial action taken as a result of the complaint.
                                    </p>

                                    <h4 className="text-md font-semibold mb-2 mt-4">Timeframes</h4>
                                    <p className="text-sm text-muted-foreground">
                                        We aim to respond to all complaints within 30 days of receipt. However, in some cases, we may require additional time to investigate the complaint fully. In such cases, we will notify the data subject of the reason for the delay and provide an estimated timeframe for resolution.
                                    </p>

                                    <h4 className="text-md font-semibold mb-2 mt-4">No Retaliation</h4>
                                    <p className="text-sm text-muted-foreground">
                                        We will not retaliate against any data subject for submitting a complaint about our data handling practices. We will take appropriate measures to prevent any retaliation by employees, service providers or contractors against data subjects who submit a complaint.
                                    </p>
                                </section>
                            </div>
                        </ScrollArea>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default PrivacyPolicy;