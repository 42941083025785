import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Plus, UserMinus } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { TeamMember, TeamResponse } from "@/types/team";
import { ConfirmDialog } from "@/components/ui/confirm-dialog";
import { useState } from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { useAuth } from "@/contexts/AuthContext";

interface MembersTabProps {
    members: TeamMember[];
    isAddingMember: boolean;
    isRemovingMember: boolean;
    addMember: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
    removeMember: (userId: string) => Promise<void>;
    team: TeamResponse;
}

export function MembersTab({ members, isAddingMember, isRemovingMember, addMember, removeMember, team }: MembersTabProps) {
    const [addMemberOpen, setAddMemberOpen] = useState(false);
    const [memberToRemove, setMemberToRemove] = useState<TeamMember | null>(null);
    const { user } = useAuth();

    const isOwner = user?.id === team.ownerId;
    const currentMember = members.find(member => member.userId._id === user?.id);

    const handleAddMember = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await addMember(e);
        setAddMemberOpen(false);
    };

    const handleRemoveMember = async () => {
        if (memberToRemove) {
            await removeMember(memberToRemove.userId._id);
            setMemberToRemove(null);
        }
    };

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
                <div className="space-y-1">
                    <CardTitle>Team Members</CardTitle>
                    <CardDescription>Manage your team members and their roles</CardDescription>
                </div>
                {isOwner && (
                    <Button variant="orange" onClick={() => setAddMemberOpen(true)}>
                        <Plus className="mr-2 h-4 w-4" />
                        Add Member
                    </Button>
                )}
            </CardHeader>
            <CardContent className="p-0">
                <div className="divide-y">
                    {members.map((member) => (
                        <div key={member._id} className="flex items-center justify-between p-4 hover:bg-accent/50 transition-colors">
                            <div className="flex items-center space-x-4">
                                <div className="h-9 w-9 rounded-full bg-primary/10 flex items-center justify-center">
                                    {member.userId.name?.[0]?.toUpperCase() || "U"}
                                </div>
                                <div>
                                    <div className="flex items-center space-x-2">
                                        <p className="text-sm font-medium leading-none">
                                            {member.userId.name || "Unnamed User"}
                                        </p>
                                        <Badge variant={member.isAdmin ? "default" : "secondary"}>
                                            {member.isAdmin ? "Admin" : "Member"}
                                        </Badge>
                                    </div>
                                    <p className="text-sm text-muted-foreground mt-1">
                                        {member.userId.email || member.userId.mobile}
                                    </p>
                                </div>
                            </div>
                            {(isOwner || member.userId._id === user?.id) && member.userId._id !== team.ownerId && (
                                <Button
                                    variant="ghost"
                                    size="icon"
                                    onClick={() => setMemberToRemove(member)}
                                    disabled={isRemovingMember}
                                >
                                    <UserMinus className="h-4 w-4" />
                                </Button>
                            )}
                        </div>
                    ))}
                    {!members.length && (
                        <div className="p-4 text-center text-sm text-muted-foreground">
                            No members yet. Add your first team member!
                        </div>
                    )}
                </div>
            </CardContent>

            <Dialog open={addMemberOpen} onOpenChange={setAddMemberOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Add Team Member</DialogTitle>
                        <DialogDescription>
                            Add a new member to your team by email or phone number.
                        </DialogDescription>
                    </DialogHeader>
                    <form onSubmit={handleAddMember} className="space-y-6">
                        <div className="space-y-4">
                            <div className="grid w-full gap-1.5">
                                <Label htmlFor="email">Email</Label>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    placeholder="member@example.com"
                                />
                            </div>
                            <div className="relative">
                                <div className="absolute inset-0 flex items-center">
                                    <span className="w-full border-t" />
                                </div>
                                <div className="relative flex justify-center text-xs uppercase">
                                    <span className="bg-background px-2 text-muted-foreground">Or</span>
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-4">
                                <div className="grid gap-1.5">
                                    <Label htmlFor="countryCode">Code</Label>
                                    <Input id="countryCode" name="countryCode" placeholder="+1" />
                                </div>
                                <div className="col-span-2 grid gap-1.5">
                                    <Label htmlFor="mobile">Phone Number</Label>
                                    <Input id="mobile" name="mobile" type="tel" placeholder="123456789" />
                                </div>
                            </div>
                            <div className="grid gap-1.5">
                                <Label htmlFor="role">Role</Label>
                                <Select name="role" defaultValue="member">
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select role" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="member">Member</SelectItem>
                                        <SelectItem value="admin">Admin</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                        <DialogFooter>
                            <Button type="button" variant="outline" onClick={() => setAddMemberOpen(false)}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="orange" disabled={isAddingMember}>
                                Add Member
                            </Button>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>

            <ConfirmDialog
                open={!!memberToRemove}
                onOpenChange={() => setMemberToRemove(null)}
                title="Remove Team Member"
                description={`Are you sure you want to remove ${memberToRemove?.userId.name || "this member"} from the team?`}
                onConfirm={handleRemoveMember}
                isLoading={isRemovingMember}
                variant="destructive"
            />
        </Card>
    );
}
