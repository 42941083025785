import { useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useApi } from "@/hooks/useApi";

interface Test {
  id: string;
  name: string;
  description: string;
  orderId?: string;
}

interface Answer {
  id: string;
  text: string;
  image?: string;
}

interface Question {
  id: string;
  text: string;
  answers: Answer[];
  selectedAnswer?: string;
}

interface Section {
  id: string;
  name: string;
  description: string;
  questions: Question[];
}

export const useSectionQuestions = (assessmentId: string) => {
  const navigate = useNavigate();
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [sections, setSections] = useState<Section[]>([]);
  const [test, setTest] = useState<Test | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  const [showDescription, setShowDescription] = useState(false); // Default to false to show questions
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isSubmittingAssessment, setIsSubmittingAssessment] = useState(false);
  const api = useApi();

  // Fetch questions by sections
  const { isLoading } = useQuery({
    queryKey: ["sectionQuestions", assessmentId],
    queryFn: async () => {
      const { data } = await api.get(
        `/assessments/${assessmentId}/questions-by-sections`
      );
      setSections(data.sections);
      setTest(data.test);
      return data;
    },
  });

  // Submit answers mutation
  const submitMutation = useMutation({
    mutationFn: async (answers: { questionId: string; selectedAnswer: string }[]) => {
      return api.post(
        `/assessments/${assessmentId}/submit-bulk`,
        { answers }
      );
    },
    onSuccess: (response) => {
      toast.success("Assessment submitted successfully");
      if (response.data?.assessment?.orderId?._id) {
        navigate(`/patient/orderDetails/${response.data.assessment.orderId._id}`, { replace: true });
      } else {
        navigate("/patient/dashboard");
      }
    },
    onError: () => {
      toast.error("Failed to submit assessment");
    },
  });

  const currentSection = sections[currentSectionIndex];

  const getAnsweredQuestionsCount = () => {
    if (!currentSection) return 0;
    return currentSection.questions.filter(q => q.selectedAnswer).length;
  };

  const isCurrentSectionComplete = () => {
    if (!currentSection) return false;
    return currentSection.questions.every(question => question.selectedAnswer);
  };

  const handleAnswerSelect = (questionId: string, answer: string) => {
    setSections((prevSections) => {
      const newSections = [...prevSections];
      const section = newSections[currentSectionIndex];
      const questionIndex = section.questions.findIndex((q) => q.id === questionId);
      
      if (questionIndex !== -1) {
        section.questions[questionIndex].selectedAnswer = answer;
      }
      
      return newSections;
    });
  };

  const submitAnswers = async () => {
    if (!currentSection) return;
    
    setIsSubmitting(true);
    try {
      // Only include questions that have answers
      const answers = currentSection.questions
        .filter(q => q.selectedAnswer) // Only questions with answers
        .map(q => ({
          questionId: q.id,
          answer: q.selectedAnswer as string // We can safely cast since we filtered nulls
        }));

      // Only submit if there are answers
      if (answers.length > 0) {
        await api.post(`/assessments/${assessmentId}/submit-bulk`, {
          answers
        });
      }
      return true;
    } catch (error) {
      console.error('Error submitting answers:', error);
      toast.error("Failed to submit answers. Please try again.");
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNext = async () => {
    if (currentSection && currentSectionIndex < sections.length - 1) {
      setIsNavigating(true);
      const success = await submitAnswers();
      if (!success) {
        setIsNavigating(false);
        return;
      }
      
      setCurrentSectionIndex(prev => prev + 1);
      // scrollQuestionsToTop(); // This function is not defined in the provided code
      setIsNavigating(false);
    }
  };

  const handlePrevious = () => {
    if (currentSectionIndex > 0) {
      setCurrentSectionIndex(prev => prev - 1);
      // scrollQuestionsToTop(); // This function is not defined in the provided code
    }
  };

  const handleSubmit = () => {
    if (!isCurrentSectionComplete()) {
      toast.error("Please answer all questions in this section");
      return;
    }

    const answers = sections.flatMap((section) =>
      section.questions
        .filter((q) => q.selectedAnswer)
        .map((q) => ({
          questionId: q.id,
          selectedAnswer: q.selectedAnswer!,
        }))
    );

    submitMutation.mutate(answers);
  };

  const handleCompleteAssessment = () => {
    setShowConfirmDialog(true);
  };

  const confirmCompleteAssessment = async () => {
    setShowConfirmDialog(false);
    if (!assessmentId) return;

    setIsSubmittingAssessment(true);
    try {
      await api.post(`/assessments/${assessmentId}/complete`);
      // Get orderId from test object
      if (test?.orderId) {
        navigate(`/patient/orderDetails/${test.orderId}`, { replace: true });
      }
    } catch (error) {
      console.error('Error completing assessment:', error);
      toast.error("Failed to complete assessment. Please try again.");
    } finally {
      setIsSubmittingAssessment(false);
    }
  };

  // Calculate progress
  const totalQuestions = sections.reduce(
    (acc, section) => acc + section.questions.length,
    0
  );
  const answeredQuestions = sections.reduce(
    (acc, section) =>
      acc + section.questions.filter((q) => q.selectedAnswer).length,
    0
  );
  const progress = (answeredQuestions / totalQuestions) * 100;

  return {
    currentSection,
    sections,
    test,
    isLoading,
    isCurrentSectionComplete,
    getAnsweredQuestionsCount,
    handleAnswerSelect,
    handleNext,
    handlePrevious,
    handleSubmit,
    isSubmitting,
    isNavigating,
    showDescription,
    setShowDescription,
    handleCompleteAssessment,
    confirmCompleteAssessment,
    showConfirmDialog,
    setShowConfirmDialog,
    isSubmittingAssessment
  };
};
