import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { IndianRupee, Users, Check, Loader2, X, ArrowLeft } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { SubscriptionPlan } from "@/types/subscription";
import { useSubscription } from "./useSubscription";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import clsx from "clsx";

export function Subscription() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const teamId = searchParams.get('teamId');
    const {
        plans,
        isLoading,
        isProcessingPayment,
        selectedPlan,
        setSelectedPlan,
        seats,
        setSeats,
        handlePurchase,
        resetState
    } = useSubscription();
    const [showDescriptionPlan, setShowDescriptionPlan] = useState<SubscriptionPlan | null>(null);

    const handleBack = () => {
        if (teamId) {
            navigate(`/teams/${teamId}`);
        } else {
            navigate(-1); // Go back to previous page
        }
    };

    if (isLoading) {
        return (
            <div className="container mx-auto px-4 py-8">
                <Button 
                    variant="ghost" 
                    className="mb-6" 
                    onClick={handleBack}
                >
                    <ArrowLeft className="h-4 w-4 mr-2" />
                    {teamId ? 'Back to Team' : 'Back'}
                </Button>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {[1, 2, 3].map((i) => (
                        <Card key={i} className="flex flex-col">
                            <CardHeader>
                                <div className="h-8 w-48 mb-2 bg-muted animate-pulse rounded" />
                                <div className="h-4 w-full bg-muted animate-pulse rounded" />
                            </CardHeader>
                            <CardContent>
                                <div className="h-12 w-32 mb-6 bg-muted animate-pulse rounded" />
                                <div className="space-y-4">
                                    {[1, 2, 3].map((j) => (
                                        <div key={j} className="h-4 w-full bg-muted animate-pulse rounded" />
                                    ))}
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            </div>
        );
    }

    if (!plans || plans.length === 0) {
        return (
            <div className="container mx-auto px-4 py-8">
                <Button 
                    variant="ghost" 
                    className="mb-6" 
                    onClick={handleBack}
                >
                    <ArrowLeft className="h-4 w-4 mr-2" />
                    {teamId ? 'Back to Team' : 'Back'}
                </Button>
                <div className="text-center">
                    <h2 className="text-2xl font-bold mb-4">No Plans Available</h2>
                    <p className="text-muted-foreground">Please check back later for available subscription plans.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <Button 
                variant="ghost" 
                className="mb-6" 
                onClick={handleBack}
            >
                <ArrowLeft className="h-4 w-4 mr-2" />
                {teamId ? 'Back to Team' : 'Back'}
            </Button>
            <div className="text-center mb-12">
                <h1 className="text-3xl font-bold mb-8">Choose Your Plan</h1>
                <p className="text-xl text-muted-foreground">
                    Select the plan that best fits your needs
                </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {plans
                    .filter((plan: SubscriptionPlan) => teamId ? plan.isTeamPlan : !plan.isTeamPlan)
                    .map((plan: SubscriptionPlan) => (
                        <Card key={plan._id} className="flex flex-col relative overflow-hidden border-2 hover:border-primary/20 transition-colors">
                            {plan.isTeamPlan && (
                                <Badge className="absolute right-4 top-4" variant="secondary">
                                    <Users className="w-3 h-3 mr-1" />
                                    Team Plan
                                </Badge>
                            )}
                            <CardHeader>
                                <div className="space-y-1">
                                    <CardTitle>{plan.name}</CardTitle>
                                    <div className="space-y-2">
                                        <div className="line-clamp-2 prose prose-sm max-w-none dark:prose-invert">
                                            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                                {plan.description}
                                            </ReactMarkdown>
                                        </div>
                                        {plan.description && plan.description.length > 100 && (
                                            <Button 
                                                variant="link" 
                                                className="p-0 h-auto text-sm"
                                                onClick={() => setShowDescriptionPlan(plan)}
                                            >
                                                More info
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </CardHeader>
                            <CardContent className="flex-grow">
                                <div className="flex items-baseline mb-6">
                                    <IndianRupee className="w-4 h-4 mr-1" />
                                    <span className="text-3xl font-bold">
                                        {plan.basePrice}
                                    </span>
                                    <span className="text-muted-foreground ml-2">/ month / user</span>
                                </div>
                                <div className="space-y-2">
                                    {plan.features.map((feature: string, i: number) => (
                                        <div key={i} className="flex items-start">
                                            <Check className="w-4 h-4 mr-2 mt-1 text-primary" />
                                            <span className="text-sm">{feature}</span>
                                        </div>
                                    ))}
                                </div>
                            </CardContent>
                            <CardFooter>
                                <Button 
                                    variant="orange" 
                                    size="lg"
                                    className="w-full"
                                    onClick={() => setSelectedPlan(plan)}
                                >
                                    Choose Plan
                                </Button>
                            </CardFooter>
                        </Card>
                    ))}
            </div>

            <Dialog 
                open={!!selectedPlan && !isProcessingPayment} 
                onOpenChange={(open) => {
                    if (!open) resetState();
                }}
            >
                <DialogContent className="sm:max-w-[500px]">
                    {!isProcessingPayment && (
                        <DialogClose onClick={resetState} className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
                            <X className="h-4 w-4" />
                            <span className="sr-only">Close</span>
                        </DialogClose>
                    )}
                    <DialogHeader>
                        <DialogTitle>Subscribe to {selectedPlan?.name}</DialogTitle>
                    </DialogHeader>

                    <div className="py-4 space-y-6">
                        {selectedPlan?.isTeamPlan && (
                            <div>
                                <Label htmlFor="seats" className="text-base">Number of Team Members</Label>
                                <Input
                                    id="seats"
                                    type="number"
                                    min={selectedPlan?.minSeats || 1}
                                    max={selectedPlan?.maxSeats || 100}
                                    value={seats}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setSeats(value); // Allow any value during editing
                                    }}
                                    placeholder={`Minimum ${selectedPlan?.minSeats || 1} seats required`}
                                    disabled={isProcessingPayment}
                                    className={clsx(
                                        "mt-2",
                                        seats && selectedPlan?.minSeats && parseInt(seats) < selectedPlan.minSeats && 
                                        "border-red-500 focus:ring-red-500"
                                    )}
                                />
                                {seats && selectedPlan?.minSeats && parseInt(seats) < selectedPlan.minSeats && (
                                    <p className="text-sm text-red-500 mt-1">
                                        Minimum {selectedPlan.minSeats} seats required
                                    </p>
                                )}
                            </div>
                        )}

                        {selectedPlan?.isTeamPlan && selectedPlan?.bulkDiscounts && (
                            <div className="space-y-2">
                                <p className="text-base">Available Volume Discounts:</p>
                                <div className="grid grid-cols-3 gap-4">
                                    {Object.entries(selectedPlan.bulkDiscounts)
                                        .sort(([discount1], [discount2]) => parseInt(discount1) - parseInt(discount2))
                                        .map(([discount, seats]) => (
                                            <div key={discount} className="text-center">
                                                <p className="text-base font-medium">{discount}% off</p>
                                                <p className="text-sm text-muted-foreground">≥ {seats} seats</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        )}

                        <div className="space-y-3">
                            <div className="flex justify-between items-center">
                                <span className="text-muted-foreground">Base Price (per user)</span>
                                <span>₹ {selectedPlan?.basePrice}</span>
                            </div>
                            {selectedPlan?.isTeamPlan && selectedPlan?.bulkDiscounts && parseInt(seats) > 0 && (
                                <div className="flex justify-between items-center">
                                    <span className="text-muted-foreground">Volume Discount</span>
                                    <span className="text-green-500">
                                        {getDiscount(parseInt(seats), selectedPlan.bulkDiscounts)}%
                                    </span>
                                </div>
                            )}
                            <div className="flex justify-between items-center pt-2 border-t">
                                <span className="font-medium">Total Monthly Cost</span>
                                <span className="font-medium">
                                    ₹ {selectedPlan ? calculateTotalPrice(selectedPlan, selectedPlan.isTeamPlan ? parseInt(seats) : 1) : 0}
                                </span>
                            </div>
                            <p className="text-sm text-muted-foreground text-center">
                                {selectedPlan?.isTeamPlan 
                                    ? `Billed monthly for ${seats} team members`
                                    : 'Billed monthly'
                                }
                            </p>
                        </div>
                    </div>

                    <DialogFooter className="gap-2">
                        <Button variant="outline" onClick={resetState} className="flex-1">
                            Cancel
                        </Button>
                        <Button 
                            variant="orange"
                            onClick={handlePurchase}
                            className="flex-1" 
                            disabled={
                                isProcessingPayment || 
                                (selectedPlan?.isTeamPlan ? (
                                    !seats || 
                                    parseInt(seats) < (selectedPlan?.minSeats || 1) ||
                                    parseInt(seats) > (selectedPlan?.maxSeats || 100)
                                ) : false)
                            }
                        >
                            Subscribe Now
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

            {/* Plan Description Modal */}
            <Dialog 
                open={!!showDescriptionPlan} 
                onOpenChange={(open) => !open && setShowDescriptionPlan(null)}
            >
                <DialogContent className="sm:max-w-[500px] max-h-[80vh] overflow-hidden flex flex-col">
                    <DialogHeader>
                        <DialogTitle>{showDescriptionPlan?.name}</DialogTitle>
                    </DialogHeader>
                    <div className="space-y-4 overflow-y-auto pr-6 -mr-6">
                        <div className="prose prose-sm max-w-none dark:prose-invert">
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                {showDescriptionPlan?.description || ''}
                            </ReactMarkdown>
                        </div>
                        {showDescriptionPlan?.bulkDiscounts && (
                            <div className="pt-4 border-t">
                                <h4 className="font-medium mb-2">Volume Discounts</h4>
                                <div className="grid grid-cols-3 gap-4">
                                    {Object.entries(showDescriptionPlan.bulkDiscounts)
                                        .sort(([seats1], [seats2]) => parseInt(seats1) - parseInt(seats2))
                                        .map(([seats, discount]) => (
                                            <div key={seats} className="text-center p-3 bg-secondary rounded-lg">
                                                <p className="font-medium">{discount}% off</p>
                                                <p className="text-sm text-muted-foreground">≥ {seats} seats</p>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                    </div>
                </DialogContent>
            </Dialog>

            {isProcessingPayment && (
                <div className="fixed inset-0 bg-background/80 backdrop-blur-sm z-50">
                    <div className="fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] sm:max-w-md w-full">
                        <div className="bg-[var(--secondary)] rounded-2xl border shadow-2xl p-8">
                            <div className="flex flex-col items-center justify-center space-y-6">
                                <div className="animate-spin rounded-full h-10 w-10 border-4 border-[var(--primary)] border-t-transparent"></div>
                                <h2 className="text-2xl font-semibold">Processing Payment</h2>
                                <p className="text-center text-muted-foreground">
                                    Please wait while we prepare your payment. Do not close or reload the browser.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

function getDiscount(seats: number, bulkDiscounts: { [key: string]: number }) {
    const discountEntries = Object.entries(bulkDiscounts)
        .map(([discount, seats]) => ({ 
            discount: parseInt(discount), 
            seats: seats  
        }))
        .sort((a, b) => b.discount - a.discount);
    
    const applicableDiscount = discountEntries.find(
        entry => seats >= entry.seats
    );
    
    return applicableDiscount ? applicableDiscount.discount : 0;
}

function calculateTotalPrice(plan: SubscriptionPlan, seats: number) {
    if (isNaN(seats) || seats < 1) return 0;
    
    let price = plan.basePrice * seats;
    
    if (plan.bulkDiscounts) {
        const discountEntries = Object.entries(plan.bulkDiscounts)
            .map(([discount, seats]) => ({ 
                discount: parseInt(discount), 
                seats: seats  
            }))
            .sort((a, b) => b.discount - a.discount);
        
        const applicableDiscount = discountEntries.find(
            entry => seats >= entry.seats
        );
        
        if (applicableDiscount) {
            price = price * (1 - applicableDiscount.discount / 100);
        }
    }
    
    return Math.round(price);
}
