import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { CreditCard, Users, Calendar, Zap, Monitor } from "lucide-react";
import { useMySubscriptions } from "./useMySubscriptions";
import { format } from "date-fns";
import { Progress } from "@/components/ui/progress";
import { Link } from "react-router-dom";
import withLayout from "@/hoc/withLayout";
import GeneralUserLayout from "@/layouts/GeneralUser/GeneralUserLayout";

function MySubscriptions() {
    const { subscriptions, isLoading } = useMySubscriptions();

    if (isLoading) {
        return (
            <div className="container mx-auto px-4 py-8">
                <div className="space-y-6">
                    {[1, 2].map((i) => (
                        <Card key={i} className="animate-pulse">
                            <CardHeader>
                                <div className="h-6 w-48 bg-muted rounded" />
                                <div className="h-4 w-32 bg-muted rounded" />
                            </CardHeader>
                            <CardContent>
                                <div className="space-y-4">
                                    {[1, 2, 3].map((j) => (
                                        <div key={j} className="h-4 w-full bg-muted rounded" />
                                    ))}
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            </div>
        );
    }

    if (!subscriptions?.length) {
        return (
            <div className="container mx-auto px-4 py-8">
                <div className="text-center">
                    <h2 className="text-2xl font-bold mb-4">No Active Subscriptions</h2>
                    <p className="text-muted-foreground mb-8">Choose a subscription type to get started</p>
                    <div className="flex flex-col sm:flex-row gap-4 justify-center">
                        <Link to="/subscription">
                            <Button variant="orange" className="w-full sm:w-auto">
                                <CreditCard className="w-4 h-4 mr-2" />
                                Get Personal Plan
                            </Button>
                        </Link>
                        <Link to="/teams">
                            <Button variant="outline" className="w-full sm:w-auto">
                                <Users className="w-4 h-4 mr-2" />
                                Setup Team Plan
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-8">My Subscriptions</h1>
            <div className="space-y-6">
                {subscriptions?.map((subscription) => (
                    <Card key={subscription._id}>
                        <CardHeader>
                            <div className="flex justify-between items-start">
                                <div>
                                    <CardTitle className="flex items-center gap-2">
                                        {subscription.plan?.name || 'Unnamed Plan'}
                                        <Badge variant={subscription.type === 'TEAM' ? 'secondary' : 'default'}>
                                            {subscription.type === 'TEAM' ? (
                                                <Users className="w-3 h-3 mr-1" />
                                            ) : (
                                                <CreditCard className="w-3 h-3 mr-1" />
                                            )}
                                            {subscription.type}
                                        </Badge>
                                        <Badge variant={subscription.status === 'ACTIVE' ? 'default' : 'destructive'}>
                                            {subscription.status}
                                        </Badge>
                                    </CardTitle>
                                    <CardDescription>
                                        <div className="flex items-center gap-2 mt-2">
                                            <Calendar className="w-4 h-4" />
                                            Valid until {format(new Date(subscription.endDate), 'PPP')}
                                        </div>
                                    </CardDescription>
                                </div>
                                <div className="text-right">
                                    <div className="text-2xl font-bold">₹{subscription.price}</div>
                                    <div className="text-sm text-muted-foreground">per month</div>
                                </div>
                            </div>
                        </CardHeader>
                        <CardContent className="space-y-4">
                            {subscription.type === 'TEAM' && (
                                <div>
                                    <div className="flex justify-between items-center mb-2">
                                        <span className="text-sm text-muted-foreground">Seats Usage</span>
                                        <span className="text-sm font-medium">
                                            {subscription.usedSeats || 0} / {subscription.seats || 0} seats
                                        </span>
                                    </div>
                                    <Progress 
                                        value={subscription.seats ? 
                                            ((subscription.usedSeats || 0) / subscription.seats) * 100 
                                            : 0
                                        } 
                                    />
                                </div>
                            )}
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                <div className="flex items-center gap-2">
                                    <Zap className="w-4 h-4" />
                                    <div>
                                        <div className="text-sm font-medium">
                                            {(subscription.creditsPerSeat || 0).toLocaleString()} credits
                                        </div>
                                        <div className="text-xs text-muted-foreground">per seat monthly</div>
                                    </div>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Monitor className="w-4 h-4" />
                                    <div>
                                        <div className="text-sm font-medium">
                                            {subscription.plan?.maxDevices || 0} devices
                                        </div>
                                        <div className="text-xs text-muted-foreground">per user</div>
                                    </div>
                                </div>
                                {subscription.type === 'TEAM' && (
                                    <div className="flex items-center gap-2">
                                        <Users className="w-4 h-4" />
                                        <div>
                                            <div className="text-sm font-medium">
                                                {subscription.teamMembers?.length || 0} members
                                            </div>
                                            <div className="text-xs text-muted-foreground">active</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </CardContent>
                        <CardFooter>
                            <div className="text-sm text-muted-foreground">
                                Access Type: {subscription.accessType || 'unknown'}
                            </div>
                        </CardFooter>
                    </Card>
                ))}
            </div>
        </div>
    );
}

export default withLayout(GeneralUserLayout)(MySubscriptions);