import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { MentalHealthData, GraphData } from "@/types/testResults";
import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";

interface Props {
    data: MentalHealthData;
}

const useChartOptions = (categories: string[]): ApexOptions => {
    return {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: { show: false },
            animations: {
                enabled: true,
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            },
            background: 'transparent'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 6,
                dataLabels: {
                    position: 'top'
                },
                columnWidth: '60%'
            }
        },
        colors: ['#6366f1'],
        dataLabels: {
            enabled: true,
            formatter: (val: number) => val?.toFixed(1) + '%' || 'N/A',
            offsetY: -20,
            style: {
                fontSize: '13px',
                fontWeight: '500',
                colors: ['#374151']
            }
        },
        xaxis: {
            categories,
            labels: {
                show: true,
                style: {
                    fontSize: '13px',
                    fontFamily: 'inherit'
                },
                rotate: -45,
                offsetY: 5
            },
            axisBorder: { show: false },
            axisTicks: { show: false }
        },
        yaxis: {
            max: 100,
            labels: {
                show: true,
                style: {
                    fontSize: '13px',
                    fontFamily: 'inherit'
                },
                formatter: (val: number) => val?.toFixed(0) + '%'
            }
        },
        grid: {
            show: true,
            borderColor: '#e5e7eb',
            strokeDashArray: 4,
            xaxis: {
                lines: { show: false }
            },
            yaxis: {
                lines: { show: true }
            },
            padding: {
                top: 20,
                right: 20,
                bottom: 20,
                left: 20
            }
        }
    };
};

export function MentalHealthReport({ data }: Props) {
    const analysis = data.analysis1[0];
    const categories = useMemo(() => analysis.graph.map((item: GraphData) => item.name), [analysis.graph]);
    const series = useMemo(() => [{
        name: 'Score',
        data: analysis.graph.map((item: GraphData) => parseFloat(item.score as string))
    }], [analysis.graph]);
    const chartOptions = useChartOptions(categories);

    return (
        <Card className="max-w-4xl mx-auto p-8 rounded-xl shadow-lg bg-[var(--background)]">
            <div className="space-y-8">
                {/* Heading Section */}
                <div className="text-center">
                    <h2 
                        className="text-2xl md:text-3xl font-bold text-white bg-[var(--primary)] px-4 py-2 rounded-md w-full block"
                    >
                        {analysis.report.topicHeading}
                    </h2>
                </div>

                {/* Description Section */}
                <div className={cn(
                    "prose prose-lg max-w-none",
                    "prose-headings:text-black prose-headings:font-semibold",
                    "prose-p:text-gray-700 prose-p:leading-relaxed",
                    "prose-strong:text-black prose-strong:font-bold"
                )}
                    dangerouslySetInnerHTML={{
                        __html: analysis.report.topicDescription
                    }}
                />

                {/* Chart Section */}
                <div className="bg-white rounded-lg p-6 shadow-sm">
                    <h3 className="text-xl font-semibold text-black mb-6">Harmony Analysis Chart</h3>
                    <div className="min-h-[400px]">
                        <ReactApexChart
                            options={chartOptions}
                            series={series}
                            type="bar"
                            height={400}
                        />
                    </div>
                </div>

                {/* Score Details Section */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {analysis.graph.map((item: GraphData, index: number) => (
                        <Card key={index} className="p-6 bg-gray-50">
                            <h4 className="text-lg font-semibold text-black mb-2">{item.name}</h4>
                            <div className="flex items-end gap-2">
                                <span className="text-3xl font-bold text-indigo-600">
                                    {parseFloat(item.score as string).toFixed(1)}%
                                </span>
                            </div>
                        </Card>
                    ))}
                </div>
            </div>
        </Card>
    );
}
