import { TestResultFactory } from "@/components/TestResults/TestResultType";
import { useTestResults } from "./useTestResults";
import PatientLayout from "@/layouts/Patient/PatientLayout";
import withLayout from "@/hoc/withLayout";
import DoctorLayout from "@/layouts/Doctor/DoctorLayout";
import { useAuth } from "@/contexts/AuthContext";
import { Skeleton } from "@/components/ui/skeleton";
import { useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";

const enum AssessmentType {
  VIKRITI = "Vikriti",
  VIKRITI_LIGHT = "Vikriti_Light",
  PRAKRITI = "Prakriti",
  MANAS = "Manas",
  TONGUE_ANALYSIS = "Tongue_Analysis",
}

const TestResultSkeleton = () => (
  <div className="container mx-auto p-6 space-y-6">
    <div className="flex items-center space-x-4">
      <Skeleton className="h-8 w-64" /> {/* Title skeleton */}
      <Skeleton className="h-8 w-16" /> {/* Test type skeleton */}
    </div>
    <div className="space-y-8">
      {/* Chart section skeletons */}
      {[1, 2, 3].map((i) => (
        <div key={i} className="space-y-4 border-b border-gray-200 pb-4">
          <Skeleton className="h-6 w-48" /> {/* Section title */}
          <Skeleton className="h-[350px] w-full" /> {/* Chart */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {[1, 2].map((j) => (
              <Skeleton
                key={j}
                className="h-32 w-full bg-gray-100 rounded-lg"
              /> /* Cards */
            ))}
          </div>
        </div>
      ))}
    </div>
  </div>
);

function TestResults() {
  const { testType } = useParams<{ testType: string }>();

  const { testResults, isLoading, isDownloading, downloadPDF } =
    useTestResults();

  if (isLoading) {
    return <TestResultSkeleton />;
  }

  if (!testResults) {
    return (
      <div className="container mx-auto p-6">
        <div className="bg-yellow-100 p-4 rounded-lg">
          No test results found 
        </div>
      </div>
    );
  }
 
  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold capitalize">
          {testType} Test Results
        </h1>
        {/* <Button
                    variant="orange"
                    onClick={() => downloadPDF()}
                    disabled={isDownloading}
                    className={`flex items-center gap-2 ${isDownloading ? 'opacity-75' : ''}`}
                >
                    {isDownloading ? (
                        <>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Generating PDF...
                        </>
                    ) : (
                        <>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clipRule="evenodd" />
                            </svg>
                            SAVE REPORT
                        </>
                    )}
                </Button> */}
      </div>
      <div id="test-results-container">
        <div className="space-y-6">
          {testType === AssessmentType.VIKRITI ||
          testType === AssessmentType.VIKRITI_LIGHT ? (
            <TestResultFactory type={testType || ""} data={testResults} />
          ) : (
            <TestResultFactory type={testType || ""} data={testResults.data} />
          )}
        </div>
      </div>
    </div>
  );
}

export function TestResultsPage() {
  const { user } = useAuth();

  if (user?.role === "DOCTOR") {
    return withLayout(DoctorLayout)(TestResults)({});
  }

  return withLayout(PatientLayout)(TestResults)({});
}
